import { Controller, useForm } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import { useMemo } from "react";
import { Box, Chip, Container, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField, Tooltip, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from '@mui/icons-material/Save';
import { useStoreActions, useStoreState } from "easy-peasy";
import { Close } from "@mui/icons-material";
import SaveAsIcon from '@mui/icons-material/SaveAs';
import HelpOutlineTwoToneIcon from '@mui/icons-material/HelpOutlineTwoTone';
import utils from '../../helpers/utils';
import ContentCopyTwoToneIcon from '@mui/icons-material/ContentCopyTwoTone';
import FormHelperTextCustom from "../common/FormHelperTextCustom";
import IconButton from '@mui/material/IconButton';


function AttributeForm() {
    const catalogAttributes = useStoreState(state => state.catalogAttributes);
    const updateCatalog = useStoreActions(state => state.updateCatalog);
    const setCatalogAttributes = useStoreActions(state => state.setCatalogAttributes);
    const attributeSelected = useStoreState(state => state.attributeSelected);
    const setShowFormAttribute = useStoreActions(state => state.setShowFormAttribute);
    const isLoading = useStoreState(state => state.loading);

    const { control, handleSubmit, watch, setValue, formState: { errors }, trigger } = useForm({
        defaultValues: {
            ...useMemo(
                () =>
                ({
                    name: attributeSelected?.name
                        ? attributeSelected?.name
                        : '',
                    type: attributeSelected?.type
                        ? attributeSelected?.type
                        : '',
                    has_prompt: utils.isFieldDefined(attributeSelected?.has_prompt)
                        ? `${attributeSelected?.has_prompt}`
                        : '',
                    prompt: attributeSelected?.prompt
                        ? `${attributeSelected?.prompt}`
                        : '',
                    options: attributeSelected?.options
                        ? attributeSelected?.options
                        : [],
                    options_text: attributeSelected?.options?.length > 0
                        ? attributeSelected?.options?.join(',')
                        : '',//local
                    unique_code: utils.isFieldDefined(attributeSelected?.unique_code)
                        ? `${attributeSelected?.unique_code}`
                        : '',
                    no_ia: utils.isFieldDefined(attributeSelected?.no_ia)
                        ? `${attributeSelected?.no_ia}`
                        : '',
                }
                ),
                [attributeSelected]
            ),
        },
        mode: "onChange",
    });


    const onSubmitOnHandler = (formData) => {

        const attributeData = {
            name: formData.name,
            options: formData.options,
            type: formData.type,
            unique_code: utils.isFieldDefined(formData.unique_code)
                ? JSON.parse(formData.unique_code)
                : '',
            no_ia: utils.isFieldDefined(formData.no_ia)
                ? JSON.parse(formData.no_ia)
                : '',
            has_prompt: utils.isFieldDefined(formData.has_prompt)
                ? JSON.parse(formData.has_prompt)
                : '',
            prompt: formData.prompt,
        }

        if (!attributeData.has_prompt && attributeData.type === 'Text Field') {
            attributeData.prompt = `Add ${attributeData.name} in a few words`
        }

        if (!attributeSelected) {//new
            setCatalogAttributes([...catalogAttributes, attributeData]);//TODO: ajustar para atualizar o state quando receber o ok da api
            updateCatalog({ attributes: [...catalogAttributes, attributeData] });
            setShowFormAttribute(false)
        } else {//update
            const index = attributeSelected.position - 1;
            const updCatalogAttributes = catalogAttributes.map((i, ind) => ind === index ? attributeData : i);
            setCatalogAttributes(updCatalogAttributes);
            updateCatalog({ attributes: updCatalogAttributes, old_name: attributeSelected.name, changed_name: formData.name !== attributeSelected.name, name: formData.name, })
            setShowFormAttribute(false)
        }
    };

    const watchAllFields = watch();
    const watchUniqueCode = watchAllFields?.unique_code && JSON.parse(watchAllFields?.unique_code);
    const watchNoIA = watchAllFields?.no_ia && JSON.parse(watchAllFields?.no_ia);
    const watchHasPrompt = watchAllFields?.has_prompt && JSON.parse(watchAllFields?.has_prompt);

    return (
        <Container maxWidth="sm">
            <form onSubmit={handleSubmit(onSubmitOnHandler)} name="attributesListForm">
                <Box display="flex" justifyContent="space-between" alignItems="center" position="relative" marginTop="5%">
                    <Typography variant="h6" style={{ color: 'black' }}>{!!attributeSelected ? `Edit Catalog "${attributeSelected.name}"` : "Create Attributes"}</Typography>
                    {!!attributeSelected && <Tooltip title="Duplicate">

                        <IconButton
                            disabled={isLoading}
                            loading={isLoading} size="medium" variant={"outlined"} onClick={() => {
                                updateCatalog({ attributes: [...catalogAttributes, { ...attributeSelected, name: `${attributeSelected.name} (1)` }] });
                                setCatalogAttributes([...catalogAttributes, { ...attributeSelected, name: `${attributeSelected.name} (1)` }])

                            }} >
                            <ContentCopyTwoToneIcon />
                        </IconButton>
                    </Tooltip>
                    }
                </Box>
                <Controller
                    name={'name'}
                    rules={{ required: true }}
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                        <FormControl {...field} error={!!errors?.name} style={{ width: '100%', marginTop: '5%' }}>
                            <TextField
                                {...field}
                                error={!!errors?.name}
                                label={'Name'}
                                disabled={isLoading}

                                variant={'filled'}
                                onChange={(e) => {
                                    if (watchAllFields?.type === 'Text Field' && watchHasPrompt) {
                                        setValue('prompt', `Add ${e.target.value} in a few words`);
                                    }
                                    field.onChange(e);
                                }}
                            />
                            <FormHelperTextCustom key={`AF-errors.name`} showHelper={!!errors?.name} />
                        </FormControl>

                    )}
                />
                {/* type */}
                <Controller
                    name={'type'}
                    rules={{ required: true }}
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                        <FormControl {...field} error={!!errors?.type} value={field.value} style={{ marginTop: '20px' }}>
                            <FormLabel id="type">Type</FormLabel>
                            <RadioGroup
                                {...field}
                                row
                                aria-labelledby="type"
                            >
                                <FormControlLabel style={{ color: `${!!errors?.type ? '#d32f2f' : 'black'}` }} value="Text Field" control={<Radio />} label="Text Field" />
                                <FormControlLabel style={{ color: `${!!errors?.type ? '#d32f2f' : 'black'}` }} value="Dropdown" control={<Radio />} label="Dropdown" />
                            </RadioGroup>
                            <FormHelperTextCustom key={`AF-errors.type`} showHelper={!!errors?.type} />
                        </FormControl>
                    )}
                />

                {watchAllFields.type === 'Dropdown' && (<div>

                    <Controller
                        name={'options'}
                        rules={{ required: true }}
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                            <FormControl {...field} error={!!errors?.options}>
                                <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginTop: '10px', marginBottom: '20px' }}>
                                    <span style={{ displa: 'flex' }}>
                                        Options Available
                                        <Tooltip title={`By pressing enter or adding a "," you can separate the multiple options`} arrow>
                                            <HelpOutlineTwoToneIcon style={{ color: '#1976d2', marginLeft: '10px', fontSize: '18px' }} />
                                        </Tooltip>
                                    </span>
                                </FormLabel>
                                <div>
                                    {watchAllFields?.options?.join(',')?.split(',').map((tag, index) => (
                                        <Chip
                                            {...field}
                                            key={index}
                                            label={tag}
                                            style={{ marginRight: '5px', marginTop: '5px' }}
                                            onDelete={() => {
                                                setValue('options', watchAllFields?.options?.filter((tagFilter) => tagFilter !== tag));
                                                setValue('options_text', watchAllFields?.options_text.replace(`,${tag}`, '').replace(tag, ''))
                                            }}
                                        />
                                    ))}
                                </div>
                            </FormControl>
                        )}
                    />
                    <Controller
                        name={'options_text'}
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                            <FormControl {...field} error={!!errors?.options} style={{ marginTop: '10px', width: '100%' }}>
                                <TextField
                                    name="options_text"
                                    error={!!errors?.options}
                                    label={''}
                                    placeholder={''}
                                    value={field.value} // Display existing tags as comma-separated string
                                    onChange={(evt) => {
                                        setValue('options', evt.target.value.split(',').map((tag) => tag.trim()));
                                        trigger('options');
                                        return field.onChange(evt);
                                    }} // Update tags on comma separation
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter' && event.target.value.trim() !== '') {
                                            event.preventDefault();
                                            event.stopPropagation();
                                        }
                                    }} // Handle Enter key press for adding tags
                                    fullWidth // Optional: Set full width for better display
                                    multiline // Optional: Allow multiple lines for longer tags
                                />
                            </FormControl>
                        )}
                    />
                </div>)}
                {watchAllFields.type === 'Text Field' && (<div>
                    {/* unique_code */}
                    <Controller
                        name={'unique_code'}
                        rules={{ required: true }}
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                            <FormControl {...field} error={!!errors?.unique_code} style={{ marginTop: '20px' }}>
                                <FormLabel id="unique_code" style={{ display: 'flex' }}>
                                    Is Unique Code Identifier?
                                    <Tooltip title={`This is the specific unique identifier of the product, it will not be generated and it for manual edit only`} arrow>
                                        <HelpOutlineTwoToneIcon style={{ color: '#1976d2', marginLeft: '10px', fontSize: '18px' }} />
                                    </Tooltip></FormLabel>
                                <RadioGroup
                                    {...field}
                                    row
                                    aria-labelledby="unique_code"
                                    onChange={(e) => {
                                        if (e?.target?.value === 'true') {
                                            setValue('has_prompt', 'false');
                                            setValue('no_ia', 'false');
                                        }
                                        field.onChange(e);
                                    }}
                                >
                                    <FormControlLabel style={{ color: `${!!errors?.unique_code ? '#d32f2f' : 'black'}` }} value={'true'} control={<Radio />} label="Yes" />
                                    <FormControlLabel style={{ color: `${!!errors?.unique_code ? '#d32f2f' : 'black'}` }} value={'false'} control={<Radio />} label="No" />
                                </RadioGroup>
                                <FormHelperTextCustom key={`AF-errors.unique_code`} showHelper={!!errors?.unique_code} />
                            </FormControl>
                        )}
                    />

                    {/* no_ia */}
                    <Controller
                        name={'no_ia'}
                        rules={{ required: true }}
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                            <FormControl
                                {...field}
                                error={!!errors?.no_ia}
                                disabled={watchUniqueCode}
                                style={{ marginTop: '20px' }}
                            >
                                <FormLabel id="no_ia" style={{ display: 'flex' }}>
                                    Remove from AI validation?
                                    <Tooltip title={`This is the specific field that cannot be generated by AI and should be ignored when generating new fields`} arrow>
                                        <HelpOutlineTwoToneIcon style={{ color: '#1976d2', marginLeft: '10px', fontSize: '18px' }} />
                                    </Tooltip></FormLabel>
                                <RadioGroup
                                    {...field}
                                    row
                                    aria-labelledby="no_ia"
                                    onChange={(e) => {
                                        if (e?.target?.value === 'true') {
                                            setValue('has_prompt', 'false');
                                        }
                                        field.onChange(e);
                                    }}
                                >
                                    <FormControlLabel style={{ color: `${!!errors?.no_ia ? '#d32f2f' : 'black'}` }} value={'true'} control={<Radio />} label="Yes" />
                                    <FormControlLabel style={{ color: `${!!errors?.no_ia ? '#d32f2f' : 'black'}` }} value={'false'} control={<Radio />} label="No" />
                                </RadioGroup>
                                <FormHelperTextCustom key={`AF-errors.no_ia`} showHelper={!!errors?.no_ia} />
                            </FormControl>
                        )}
                    />

                    {/* has_prompt */}
                    <Controller
                        name={'has_prompt'}
                        rules={{ required: true }}
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                            <FormControl
                                {...field}
                                error={!!errors?.has_prompt}
                                disabled={watchUniqueCode || watchNoIA}
                                style={{ marginTop: '20px' }}>
                                <FormLabel id="has_prompt" style={{ display: 'flex' }}>Has Custom Prompt?</FormLabel>
                                <RadioGroup
                                    {...field}

                                    row
                                    aria-labelledby="has_prompt"
                                >
                                    <FormControlLabel style={{ color: `${!!errors?.has_prompt ? '#d32f2f' : 'black'}` }} value={'true'} control={<Radio />} label="Yes" />
                                    <FormControlLabel style={{ color: `${!!errors?.has_prompt ? '#d32f2f' : 'black'}` }} value={'false'} control={<Radio />} label={
                                        <span style={{ display: 'flex' }}>
                                            No
                                            <Tooltip title={`By selecting this option the prompt will automatically be set to "Add ${watchAllFields.name ? watchAllFields.name : '{attibute_name}'} in a few words"`} arrow>
                                                <HelpOutlineTwoToneIcon style={{ color: '#1976d2', marginLeft: '10px', fontSize: '18px' }} />
                                            </Tooltip>
                                        </span>} />
                                </RadioGroup>
                                <FormHelperTextCustom key={`AF-errors.has_prompt`} showHelper={!!errors?.has_prompt} />
                            </FormControl>
                        )}
                    />
                    {watchAllFields?.has_prompt && JSON.parse(watchAllFields?.has_prompt) && <div><Controller
                        name={'prompt'}
                        rules={{ required: true }}
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                            <FormControl  {...field} value={field.value} style={{ width: '100%', marginTop: '20px' }}>
                                <FormLabel id="prompt">Prompt</FormLabel>
                                <TextField
                                    {...field}
                                    error={!!errors?.prompt}
                                    multiline
                                    rows={3}
                                />
                                <FormHelperTextCustom key={`AF-errors.prompt`} showHelper={!!errors?.prompt} />
                            </FormControl>
                        )}
                    /></div>}
                </div>)
                }
                <LoadingButton loading={isLoading} startIcon={<Close />} style={{ minWidth: '45%', marginTop: '20px', }} size="small" variant={"outlined"} onClick={() => { setShowFormAttribute(false) }} >Close</LoadingButton>
                <LoadingButton loading={isLoading} startIcon={attributeSelected ? <SaveAsIcon /> : <SaveIcon />} style={{ minWidth: '45%', marginTop: '20px', float: 'right' }} size="small" variant={"contained"}
                    type="submit" >{!attributeSelected ? 'Create' : 'Save'}</LoadingButton>
            </form >

            <DevTool control={control} /> {/* set up the dev tool */}
        </Container >
    );
};

export default AttributeForm; 