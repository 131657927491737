import ExcelJS from 'exceljs';
import { useState } from "react";
import utils from '../../helpers/utils';
import { LoadingButton } from "@mui/lab";
import PreviewTable from "./PreviewTable";
import { Close } from "@mui/icons-material";
import { useDropzone } from 'react-dropzone';
import InfoIcon from '@mui/icons-material/Info';
import SaveIcon from '@mui/icons-material/Save';
import { useStoreActions, useStoreState } from "easy-peasy";
import LayersClearIcon from '@mui/icons-material/LayersClear';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import { Chip, Container, Tooltip, Typography } from "@mui/material";
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';


function UploadContaner() {

  const [previewProducts, setPreview] = useState([]);
  const images = useStoreState(state => state.images);
  const products = useStoreState(state => state.products);
  const [errorMessage, setErrorMessage] = useState(null);
  const catalogAttributes = useStoreState(state => state.catalogAttributes);
  const updateCatalogProducts = useStoreActions(state => state.updateCatalogProducts);
  const setShowUploadProducts = useStoreActions(state => state.setShowUploadProducts);
  const replaceCatalogProducts = useStoreActions(state => state.replaceCatalogProducts);


  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (acceptedFiles) => {
      const unallowedType = acceptedFiles.filter(i => !i.type.includes('spreadsheet'));
      const overflowdImageSize = acceptedFiles.filter(i => utils.bytesToMegaBytes(i.size) > 4);
      //Error Handling
      if (acceptedFiles.length > 1) return setErrorMessage('Only one file of categories expected')
      if (unallowedType.length > 0) return setErrorMessage('Unexpected type of file uploaded');
      else if (overflowdImageSize.length > 0) return setErrorMessage('File uploaded must be below 4MB');
      else setErrorMessage(null);

      const setProductFromFile = async (fileUploaded) => {
        const workbook = new ExcelJS.Workbook();
        const arrayBuffer = await fileUploaded.arrayBuffer();
        await workbook.xlsx.load(arrayBuffer);
        const worksheetOutput = workbook.getWorksheet("Product Listing");

        if (!worksheetOutput) return setErrorMessage('Unexpected type of file uploaded');

        let outputFields = [];

        for (let row = 2; row <= worksheetOutput.rowCount; row++) {
          let outputVariable = {};
          for (let column = 1; column <= worksheetOutput.columnCount; column++) {
            if (!['Product Index', 'id'].includes(worksheetOutput.getCell(1, column).value) && !!worksheetOutput.getCell(1, column).value) {
              const cell = worksheetOutput.getCell(row, column);
              const type = catalogAttributes?.find(i => i.name === worksheetOutput.getCell(1, column).value)?.type;
              if ((!!cell.value && type) || (!!cell.value &&  worksheetOutput.getCell(1, column).value === "Product Image File Name")) {
                
                if (type === 'Dropdown') outputVariable[worksheetOutput.getCell(1, column).value] = cell.value;

                else outputVariable[worksheetOutput.getCell(1, column).value] = cell.value;
              }
            }
          }
          if (!!outputVariable?.['Product Image File Name']) {
            outputVariable['Product Image File Name'] = outputVariable?.['Product Image File Name'].split(',').map(i => images.find(x => x.original_name === i.trim())?.stored_name);
            if(outputVariable['Product Image File Name'].filter(i => !i).length > 0) return setErrorMessage('Some images not found on the server. Please update the catalog and try again.');
          }
          else outputVariable['Product Image File Name'] = [];
          outputFields.push(outputVariable)
        }
        setPreview(outputFields)
      }

      setProductFromFile(acceptedFiles[0]);

    },
  });

  console.log(previewProducts)
  return (
    <Container maxWidth="sm">
      <Typography variant="h6" style={{ color: 'black', marginTop: '5%' }}>{previewProducts.length > 0 ? 'Preview' : 'Upload'} Excel Template</Typography>
      {previewProducts.length > 0 && <Tooltip title="Clear catalog products previewed"><LoadingButton loading={false} style={{ marginTop: '-30px', position: 'absolute', right: '25px' }} size="small" variant={"outlined"} onClick={() => { setPreview([]) }} ><LayersClearIcon /></LoadingButton></Tooltip>}
      {previewProducts.length === 0 && <>
        <div style={{ marginTop: '5%' }}>
          Template:
          <div style={{ textAlign: 'left', marginBottom: '10px', display: 'inline-block' }}>
            <Chip icon={<FilePresentIcon color="#61dafb" />} className="download-chip-custom-styles" label={'template_products.xlsx'} clickable onClick={() => utils.downloadTable(catalogAttributes, !!products?.[0] ? [products?.[0]] : [], {fileName: 'template_products.xlsx', hideAttributes:true})} />
          </div>
        </div>
        <div style={{ marginTop: '5%' }}>
          <div className='drag-and-drop-custom-styles' {...getRootProps()}>
            <input {...getInputProps()} />
            <p>Drag and drop file here or click to browse.</p>
          </div>
          {errorMessage && <div style={{ display: 'flex', marginTop:'10px' }}><InfoIcon />{errorMessage}</div>}
        </div>
      </>}
      {previewProducts.length > 0 && <PreviewTable products={previewProducts} />}
      <LoadingButton loading={false} startIcon={<Close />} style={{ minWidth: '45%', marginTop: '20px', }} size="small" variant={"outlined"} onClick={() => { setShowUploadProducts(false) }} >Close</LoadingButton>
      {previewProducts.length > 0 &&
        <LoadingButton loading={false} startIcon={<SaveIcon />} style={{ minWidth: '25%', marginTop: '20px', float: 'right', marginLeft: '10px' }} size="small" variant={"contained"} onClick={() => { updateCatalogProducts(previewProducts); setShowUploadProducts(false); }} >Save</LoadingButton>
      }
      {previewProducts.length > 0 && <LoadingButton loading={false} startIcon={<PublishedWithChangesIcon />} style={{ minWidth: '25%', marginTop: '20px', float: 'right' }} size="small" variant={"contained"} onClick={() => { replaceCatalogProducts(previewProducts); setShowUploadProducts(false); }} >Replace</LoadingButton>}
    </Container>
  );
};

export default UploadContaner;