import { Box, Checkbox, Container, FormControlLabel, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import { LoadingButton } from "@mui/lab";
import { useStoreActions, useStoreState } from "easy-peasy";
import { Close } from "@mui/icons-material";
import SaveIcon from '@mui/icons-material/Save';
import CustomAutocomplete from '../common/CustomAutocomplete';
import LayersClearIcon from '@mui/icons-material/LayersClear';
import CustomAutocompleteAttributes from '../common/CustomAutocompleteAttributes';
import PreviewTable from './PreviewTable';
import Lottie from 'react-lottie';
import animationData from '../../assets/enrich-animation.json';
import ReplayIcon from '@mui/icons-material/Replay';
import IconButton from '@mui/material/IconButton';

const defaultOptions = {
  loop: true, // Set to true for continuous animation
  autoplay: true, // Set to true for automatic playback
  animationData, // Replace with your animation data
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice' // Adjust aspect ratio as needed
  }
}
export default function EnrichContent() {
  const loading = useStoreState(state => state.isEnrichLoading);
  const products = useStoreState(state => state.products);
  const lastRequestDuration = useStoreState(state => state.lastRequestDuration);
  const [productsSelected, setProductsSelected] = useState([]);
  const setShowEnrich = useStoreActions(state => state.setShowEnrich);
  const enrichProducts = useStoreActions(state => state.enrichProducts);
  const previewProducts = useStoreState(state => state.previewProducts);
  const [attributesToGenerate, setAttributesToGenerate] = useState([]);
  const catalogAttributes = useStoreState(state => state.catalogAttributes);
  const [isCorrectFixAllProducts, setIsCorrectFixAllProducts] = useState(false);
  const setPreviewProducts = useStoreActions(state => state.setPreviewProducts);
  const updateMultipleProductsByCatalog = useStoreActions(state => state.updateMultipleProductsByCatalog);
  const attributesAvailable = catalogAttributes.filter(i => !i.no_ia && !i.unique_code);



  const actionEnrich = () => {
    const fields = attributesAvailable.filter(i => !attributesToGenerate.find(x => x.name === i.name));
    if (fields.length === 0) return "No fields to compare to for enrich";

    const info = {
      result: isCorrectFixAllProducts ? null : productsSelected,
      fields,
      newFields: attributesToGenerate
    }
    enrichProducts(info);
  }
  const applyEnrich = () => {
    let array = JSON.parse(JSON.stringify(previewProducts));
    array = array.map(item => JSON.parse(JSON.stringify(item)));
    array = array.map(p => {
      const images_arr = p.images_arr;
      delete p.created_at;
      delete p.updated_at;
      delete p.images_arr;
      return {
        productId: p.id,
        attributes: p,
        images_arr: images_arr.map(i => i.stored_name)
      };
    });
    updateMultipleProductsByCatalog(array);
  }
  return (
    <Container maxWidth="sm" display="flex" justifyContent="space-between" alignItems="center" position="relative" style={{ marginTop: '5%' }}>
      {!loading && previewProducts.length > 0 &&
        <>
          <Box display="flex" justifyContent="space-between" alignItems="center" position="relative" marginTop="5%">
            <Typography variant="h6" style={{ color: 'black', }}>Enrich</Typography>
            <Box display="flex" alignItems="center">
              <Tooltip title="Enrich products">
                <IconButton
                  loading={false}
                  style={{ marginRight: '10px' }}
                  size="medium"
                  variant={"outlined"}
                  onClick={() => { actionEnrich(); }}
                >
                  <ReplayIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Clear enrich made">
                <IconButton loading={false} style={{}} size="medium" variant={"outlined"} onClick={() => { setPreviewProducts([]) }} >
                  <LayersClearIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          <PreviewTable products={previewProducts} />
          {lastRequestDuration && <div style={{ fontWeight: '300', mt: '20px' }}><b>Note:</b> Product Enriched lasted {lastRequestDuration / 1000} seconds for {previewProducts.length} product(s)</div>}
        </>}
      {!loading && previewProducts.length === 0 && <>
        <FormControlLabel control={<Checkbox checked={isCorrectFixAllProducts} onChange={(e) => setIsCorrectFixAllProducts(e.target.checked)} />} label="All Products" />
        {!isCorrectFixAllProducts && <div style={{ marginTop: '20px', marginBottom: '20px', width: '100%' }}>
          <CustomAutocomplete products={products} productsSelected={productsSelected} setProductsSelected={setProductsSelected} />
        </div>}
        <div style={{ marginTop: '20px', marginBottom: '20px', width: '100%' }}>
          <CustomAutocompleteAttributes attributesToGenerate={attributesToGenerate} setAttributesToGenerate={setAttributesToGenerate} attributesAvailable={attributesAvailable} />
        </div>
      </>}
      {loading && <div style={{ textAlign: 'center' }}>
        <Lottie options={defaultOptions} height={350} width={'100%'} />
        <span style={{ color: 'rgba(0, 0, 0, 0.6)', marginTop: '20px' }}>Waiting for product generation ...</span>
      </div>}

      {previewProducts.length === 0 && <LoadingButton loading={loading} startIcon={<SaveIcon />} style={{ minWidth: '45%', marginTop: '20px', float: 'right', marginLeft: '10px' }} size="small" variant={"contained"} onClick={actionEnrich} >Enrich</LoadingButton>}
      {previewProducts.length > 0 && <LoadingButton loading={loading} startIcon={<SaveIcon />} style={{ minWidth: '45%', marginTop: '20px', float: 'right', marginLeft: '10px' }} size="small" variant={"contained"} onClick={applyEnrich} >Apply</LoadingButton>}
      <LoadingButton loading={loading} startIcon={<Close />} style={{ minWidth: '45%', marginTop: '20px', }} size="small" variant={"outlined"} onClick={() => { setShowEnrich(false) }} >Close</LoadingButton>

    </Container>
  )
}
