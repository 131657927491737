import { Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, Chip, Container, FormControlLabel, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import { LoadingButton } from "@mui/lab";
import { useStoreActions, useStoreState } from "easy-peasy";
import { Close } from "@mui/icons-material";
import SaveIcon from '@mui/icons-material/Save';
import CustomAutocomplete from '../common/CustomAutocomplete';
import Lottie from 'react-lottie';
import animationData from '../../assets/search-error-animation.json';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import LayersClearIcon from '@mui/icons-material/LayersClear';
import ReplayIcon from '@mui/icons-material/Replay';
import IconButton from '@mui/material/IconButton';

const defaultOptions = {
  loop: true, // Set to true for continuous animation
  autoplay: true, // Set to true for automatic playback
  animationData, // Replace with your animation data
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice' // Adjust aspect ratio as needed
  }
}
export default function CorrectFixContent() {

  const loading = useStoreState(state => state.isCorrectFixLoading);
  const products = useStoreState(state => state.products);
  const setFixes = useStoreActions(state => state.setFixes);
  const [productsSelected, setProductsSelected] = useState([]);
  const previewFixes = useStoreState(state => state.previewFixes);
  const setPreviewFixes = useStoreActions(state => state.setPreviewFixes);
  const searchForErrors = useStoreActions(state => state.searchForErrors);
  const setShowCorrectFix = useStoreActions(state => state.setShowCorrectFix);
  const [isCorrectFixAllProducts, setIsCorrectFixAllProducts] = useState(false);
  const lastRequestDuration = useStoreState(state => state.lastRequestDuration);
  return (
    <Container maxWidth="sm" display="flex" justifyContent="space-between" alignItems="center" position="relative" marginTop="5%">
      <Box display="flex" justifyContent="space-between" alignItems="center" position="relative" marginTop="5%">
        <Typography variant="h6" style={{ color: 'black', }}>Correct/Fix</Typography>
        {previewFixes.length > 0 &&
          <Box display="flex" alignItems="center">
            <Tooltip title="Regenerate corrections and fixes">
              <IconButton
                loading={false}
                style={{ marginRight: '10px' }}
                size="medium" variant={"outlined"}
                onClick={() => { setPreviewFixes([]); searchForErrors(isCorrectFixAllProducts ? null : productsSelected); }} >
                <ReplayIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Clear corrections and fixes"><IconButton
              loading={loading}
              style={{ marginRight: '10px' }}
              size="medium"
              variant={"outlined"}
              onClick={() => { setPreviewFixes([]) }}
            >
              <LayersClearIcon />
            </IconButton>
            </Tooltip>
          </Box>}
      </Box>

      {loading && <div style={{ textAlign: 'center' }}>
        <Lottie options={defaultOptions} height={350} width={'100%'} />
        <span style={{ marginTop: '20px' }}>Searching for product errors and fixes ...</span>
      </div>}
      {!loading && previewFixes.length === 0 && <>
        <FormControlLabel control={<Checkbox checked={isCorrectFixAllProducts} onChange={(e) => setIsCorrectFixAllProducts(e.target.checked)} />} label="All Products" />

        {!isCorrectFixAllProducts &&
          <div style={{ marginTop: '20px', marginBottom: '20px', width: '100%' }}>
            <CustomAutocomplete products={products} productsSelected={productsSelected} setProductsSelected={setProductsSelected} />
          </div>
        }
        <div></div>
      </>}
      {previewFixes.length > 0 && <>
        {/* Result */}
        <div style={{ marginTop: '20px' }}> <b> Result:</b> {previewFixes.length} products analysed, {previewFixes.filter(i => i.Correct === 'No').length} errors found lasting {lastRequestDuration / 1000} seconds</div>
        {previewFixes.filter(i => i.Correct === 'No').length > 0 && <div>
          <Typography sx={{ mt: 4, mb: 2 }} component="div">
            <b>More Information</b>
          </Typography>
          {previewFixes.filter(i => i.Correct === 'No').map((itemToFix, index) => (<Accordion key={index}>
            <AccordionSummary
              expandIcon={<ArrowDownwardIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              style={{ backgroundColor: itemToFix.Correct === 'Yes' ? 'rgba(0, 255, 0, 0.1)' : 'rgba(255, 0, 0, 0.1)' }}
            >
              <Typography><Chip style={{ backgroundColor: '#dddddd', border: '1px solid #bbb9b9' }} label={`Product #${itemToFix.product_id}`} /> <b>Incorrect</b></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {itemToFix.Why}
              </Typography>
            </AccordionDetails>
          </Accordion>))}
        </div>}
      </>}

      {previewFixes.length === 0 && <LoadingButton loading={loading} startIcon={<SaveIcon />} style={{ minWidth: '45%', marginTop: '20px', float: 'right', marginLeft: '10px' }} size="small" variant={"contained"} onClick={() => { searchForErrors(isCorrectFixAllProducts ? null : productsSelected) }} >Search</LoadingButton>}
      {previewFixes.filter(i => i.Correct === 'No').length > 0 && <LoadingButton loading={loading} startIcon={<SaveIcon />} style={{ minWidth: '45%', marginTop: '20px', float: 'right', marginLeft: '10px' }} size="small" variant={"contained"} onClick={() => { setFixes(previewFixes); setShowCorrectFix(false); setPreviewFixes([]); }} >Apply</LoadingButton>}
      <LoadingButton loading={loading} startIcon={<Close />} style={{ minWidth: '45%', marginTop: '20px', }} size="small" variant={"outlined"} onClick={() => { setShowCorrectFix(false) }} >Close</LoadingButton>
    </Container>
  )
}
