import { thunk } from 'easy-peasy';
import axios from '../helpers/axiosConfig';
import { jwtDecode } from 'jwt-decode';

import constants from '../helpers/constants';
const thunks = {
    authenticate: thunk(async (actions, info, { getStoreState }) => {
        try {
            if (!info.username || !info.password) throw new Error('Missing username or password');
            actions.setisAuthLoading(true);
            const response = await axios.request({
                url: `${constants.baseurl}/auth/login`,
                method: 'POST',
                headers: {
                    'Authorization': `Basic ${btoa(`${info.username}:${info.password}`)}`, // Encode credentials in Base64
                    'Content-Type': 'application/json',
                },
                data: info
            }).catch(error => {
                throw new Error(error.message);
            })
            actions.setAuth({
                token: response?.data?.token,
                decodedToken: jwtDecode(response?.data?.token)
            })
            actions.setisAuthLoading(false);
            actions.fetchAllCatalogs()
            actions.setTabSelected('Catalog List')
        } catch (error) {
            actions.setisAuthLoading(false);
            actions.setNotification({ type: 'error', message: 'Error authenticating' });
            throw error;
        }
    }),
    fetchApplicationOptions: thunk(async (actions, info, { getStoreState }) => {
        try {
            const response = await axios.request({
                url: `${constants.baseurl}/version`,
                method: 'GET',
                headers: {
                    'Authorization': `JWT ${getStoreState()?.auth?.token}`, // Encode credentials in Base64
                },
            })
            actions.setApplicationOptions(response.data)
        } catch (error) {
            actions.setNotification({ type: 'error', message: 'Error fetching application options' });
        }
    }),
    uploadImages: thunk(async (actions, info, { getStoreState }) => {
        try {
            actions.setLoading(true);
            const response = await axios.request({
                url: `${constants.baseurl}/images?catalog_id=${getStoreState()?.catalogSelected?.id}`,
                method: 'POST',
                data: info,
                headers: {
                    'Authorization': `JWT ${getStoreState()?.auth?.token}`, // Encode credentials in Base64
                },
            })
            actions.setShowSavedImages(true)
            if (response?.data?.uploadedFiles) actions.updateCatalog({ images: response.data.uploadedFiles })
            actions.fetchAllImagesFromProject()
        } catch (error) {
            actions.setLoading(true);
            actions.setNotification({ type: 'error', message: 'Error uploading images' });
        }
    }),
    replaceImages: thunk(async (actions, info, { getStoreState }) => {
        try {
            actions.setLoading(true);
            const response = await axios.request({
                url: `${constants.baseurl}/images?catalog_id=${getStoreState()?.catalogSelected?.id}&replace=true`,
                method: 'POST',
                data: info,
                headers: {
                    'Authorization': `JWT ${getStoreState()?.auth?.token}`, // Encode credentials in Base64
                },
            })
            actions.setShowSavedImages(true)
            if (response?.data?.uploadedFiles) actions.updateCatalog({ images: response.data.uploadedFiles })
            actions.fetchAllImagesFromProject()
        } catch (error) {
            actions.setNotification({ type: 'error', message: 'Error replacing images' });
            actions.setLoading(false);
        }
    }),
    fetchSavedImages: thunk(async (actions, info, { getStoreState }) => {
        try {
            actions.setLoading(true);

            const response = await axios.request({
                url: `${constants.baseurl}/images`,
                method: 'GET',
                headers: {
                    'Authorization': `JWT ${getStoreState()?.auth?.token}`, // Encode credentials in Base64
                },
            })
            actions.setSavedImages(response.data.filter(i => i !== 'images/'));
            actions.setLoading(false);

        } catch (error) {
            actions.setLoading(false);
            actions.setNotification({ type: 'error', message: 'Error fetching saved images' });
        }
    }),
    fetchAllCatalogs: thunk(async (actions, info, { getStoreState }) => {
        try {
            actions.setLoading(true);
            const response = await axios.request({
                url: `${constants.baseurl}/catalogs`,
                method: 'GET',
                headers: {
                    'Authorization': `JWT ${getStoreState()?.auth?.token}`, // Encode credentials in Base64
                },
            })
            actions.setCatalogs(response.data.body.map(i => ({ ...i, saved: true, attributes: !!i.attributes ? i.attributes.map(attribute => ({ ...attribute, options: attribute.options.filter(i => !!i).map(i => i.trim().replaceAll('/n', '')) })) : [] })));
            //Check if catalog is selected and set it (updates changes in catalog)
            if (!!getStoreState().catalogSelected) actions.setCatalogSelected(response.data.body.find(i => i.id === getStoreState().catalogSelected.id));

            actions.setLoading(false);
        } catch (error) {
            actions.setLoading(false);
            actions.setNotification({ type: 'error', message: 'Error fetching catalogs' });
        }
    }),
    fetchAllImagesFromProject: thunk(async (actions, info, { getStoreState }) => {
        try {
            actions.setLoading(true);
            const response = await axios.request({
                url: `${constants.baseurl}/catalogs/${getStoreState().catalogSelected.id}/images`,
                method: 'GET',
                headers: {
                    'Authorization': `JWT ${getStoreState()?.auth?.token}`, // Encode credentials in Base64
                },
            })
            actions.setImages(response.data.images);
            actions.setImagesByProduct(response.data.images_by_product);
            actions.setLoading(false);
        } catch (error) {
            actions.setNotification({ type: 'error', message: 'Error fetching images' });
            actions.setLoading(false);
        }
    }),
    fetchAllProductsFromProject: thunk(async (actions, info, { getStoreState }) => {
        try {
            actions.setLoading(true);
            const response = await axios.request({
                url: `${constants.baseurl}/catalogs/${getStoreState().catalogSelected.id}/products`,
                method: 'GET',
                headers: {
                    'Authorization': `JWT ${getStoreState()?.auth?.token}`, // Encode credentials in Base64
                },
            }).catch(error => {
                throw new Error(error.message);
            })
            //TODO: Remove fix data
            actions.setProducts(response.data.products.map(i => ({ id: i.id, ...i.attribute_values, images_arr: i.images_arr, created_at: i.created_at, updated_at: i.updated_at })));
            actions.setLoading(false);
        } catch (error) {
            actions.setLoading(false);
            actions.setNotification({ type: 'error', message: 'Error fetching products' });
        }
    }),
    generateMyCatalog: thunk(async (actions, info, { getStoreState }) => {
        try {
            actions.setIsGenerateIALoading(true);
            const response = await axios.request({
                url: `${constants.baseurl}/process-v2?type=final-cataloguer-lambda`,
                method: 'POST',
                data: {
                    fields: info.attributesToGenerate,
                    uploadedFiles: info.previewImages,
                },
                headers: {
                    'Authorization': `JWT ${getStoreState()?.auth?.token}`, // Encode credentials in Base64
                },
            }).catch(error => {
                actions.setIsGenerateIALoading(false);
                throw new Error(error.message);
            })

            actions.setIsGenerateIALoading(false);
            const products = response.data.result.map((i, index) => {
                return ({ ...i, 'Product Image File Name': info.previewImages[index] })
            });
            actions.setPreviewProducts(products);
            actions.setLastRequestDuration(response.duration)
        } catch (error) {
            actions.setLoading(false);
            actions.setNotification({ type: 'error', message: 'Error generating catalog' });
        }
    }),
    searchForErrors: thunk(async (actions, info, { getStoreState }) => {
        try {
            if (!getStoreState()?.products) return

            actions.setIsCorrectFixLoading(true);
            let products = JSON.parse(JSON.stringify(getStoreState().products))
            if (info && Array.isArray(info)) products = info;

            const response = await axios.request({
                url: `${constants.baseurl}/process-v2?type=final_anomaly_function`,
                method: 'POST',
                headers: {
                    'Authorization': `JWT ${getStoreState()?.auth?.token}`, // Encode credentials in Base64
                },
                data: {
                    fields: getStoreState().catalogAttributes,
                    uploadedFiles: products.map(i => !!i?.images_arr ? i?.images_arr.map(x => x.stored_name) : []),
                    result: products.map(i => { delete i['images_arr']; return i; })
                }
            }).catch(error => {
                actions.setIsCorrectFixLoading(false);
                throw new Error(error.message);
            })
            actions.setPreviewFixes(response.data.fixes.map((i, index) => ({ ...i, 'product_id': products[index].id })))
            actions.setIsCorrectFixLoading(false);
            actions.setLastRequestDuration(response.duration)
        } catch (error) {
            actions.setLoading(false);
            actions.setNotification({ type: 'error', message: 'Error searching for errors' });
        }
    }),
    enrichProducts: thunk(async (actions, info, { getStoreState }) => {
        try {
            if (!getStoreState()?.products) return

            actions.setIsEnrichLoading(true);
            let products = JSON.parse(JSON.stringify(getStoreState().products))
            if (!info.result) info.result = products;
            else products = info.result;

            info.result = info.result.filter(i => !i.no_ia).map(product => ({
                ...product,
                images_arr: undefined,
                id: undefined,
                updated_at: undefined,
                created_at: undefined
            }));
            info.uploadedFiles = products.map(i => !!i?.images_arr ? i?.images_arr.map(x => x.stored_name) : []);
            const response = await axios.request({
                url: `${constants.baseurl}/process-v2?type=final_enrich_function`,
                method: 'POST',
                headers: {
                    'Authorization': `JWT ${getStoreState()?.auth?.token}`, // Encode credentials in Base64
                },
                data: info
            }).catch(error => {
                actions.setIsEnrichLoading(false);
                throw new Error(error.message);
            })
            actions.setPreviewProducts(products.map((i, index) => ({ ...i, ...response?.data?.fixes?.[index] })))
            actions.setIsEnrichLoading(false);
            actions.setLastRequestDuration(response.duration)
        } catch (error) {
            actions.setLoading(false);
            actions.setNotification({ type: 'error', message: 'Error enriching products' });
        }
    }),
    createCatalog: thunk(async (actions, info, { getStoreState }) => {
        try {
            actions.setLoading(true);
            await axios.request({
                url: `${constants.baseurl}/catalogs`,
                method: 'POST',
                data: info,
                headers: {
                    'Authorization': `JWT ${getStoreState()?.auth?.token}`, // Encode credentials in Base64
                },
            }).catch(error => {
                throw new Error(error.message);
            })
            actions.setLoading(false);
            actions.fetchAllCatalogs()
            actions.setNotification({ type: 'success', message: 'Catalog created successfully' });
        } catch (error) {
            actions.setLoading(false);
            actions.setNotification({ type: 'error', message: 'Error creating catalog' });
        }
    }),
    deleteImage: thunk(async (actions, info, { getStoreState }) => {
        try {
            actions.setLoading(true);
            await axios.request({
                url: `${constants.baseurl}/images/${info}`,
                method: 'DELETE',
                headers: {
                    'Authorization': `JWT ${getStoreState()?.auth?.token}`, // Encode credentials in Base64
                },
            }).catch(error => {
                throw new Error(error.message);
            })
            actions.setLoading(false);
            actions.fetchAllImagesFromProject();
            actions.setNotification({ type: 'success', message: 'Image deleted successfully' });
        } catch (error) {
            actions.setLoading(false);
            actions.setNotification({ type: 'error', message: 'Error deleting image' });
        }
    }),
    duplicateImage: thunk(async (actions, info, { getStoreState }) => {
        try {
            actions.setLoading(true);
            await axios.request({
                url: `${constants.baseurl}/images/${info}/duplicate`,
                method: 'POST',
                headers: {
                    'Authorization': `JWT ${getStoreState()?.auth?.token}`, // Encode credentials in Base64
                },
            }).catch(error => {
                throw new Error(error.message);
            })
            actions.setLoading(false);
            actions.fetchAllImagesFromProject()
            actions.setNotification({ type: 'success', message: 'Image duplicated successfully' });
        } catch (error) {
            actions.setLoading(false);
            actions.setNotification({ type: 'error', message: 'Error duplicating image' });
        }
    }),
    updateCatalog: thunk(async (actions, info, { getStoreState }) => {
        try {
            actions.setLoading(true);
            await axios.request({
                url: `${constants.baseurl}/catalogs/${getStoreState()?.catalogSelected?.id}`,
                method: 'put',
                headers: {
                    'Authorization': `JWT ${getStoreState()?.auth?.token}`, // Encode credentials in Base64
                },
                data: info
            }).catch(error => {
                throw new Error(error.message);
            })
            actions.fetchAllCatalogs()
            actions.setLoading(false);
            actions.setNotification({ type: 'success', message: 'Catalog updated successfully' });
            if (!!info?.images) actions.setSavedImages(info?.images.map(i => `images/${i}`));
        } catch (error) {
            actions.setLoading(false);
            actions.setNotification({ type: 'error', message: 'Error updating catalog' });
        }
    }),
    updateAttributesCatalog: thunk(async (actions, data, { getStoreState }) => {
        try {
            actions.setLoading(true);
            await axios.request({
                url: `${constants.baseurl}/catalogs/${getStoreState()?.catalogSelected?.id}`,
                method: 'put',
                headers: {
                    'Authorization': `JWT ${getStoreState()?.auth?.token}`, // Encode credentials in Base64
                },
                data: data.payload,
                signal: data.abortController?.signal,
            }).catch(error => {
                throw new Error(error.message);
            })
            actions.setLoading(false);
            actions.setCatalogAttributes(data.payload.attributes);
            actions.setNotification({ type: 'success', message: 'Attributes updated successfully' });
        } catch (error) {
            actions.setLoading(false);
            actions.setNotification({ type: 'error', message: 'Error updating catalog' });
        }
    }),
    deleteCatalog: thunk(async (actions, info, { getStoreState }) => {
        try {
            actions.setLoading(true);
            await axios.request({
                url: `${constants.baseurl}/catalogs/${info}`,
                method: 'delete',
                headers: {
                    'Authorization': `JWT ${getStoreState()?.auth?.token}`, // Encode credentials in Base64
                },
            }).catch(error => {
                throw new Error(error.message);
            });
            actions.setNotification({ type: 'success', message: 'Catalog deleted successfully' });
            actions.fetchAllCatalogs()
            actions.setLoading(false);
        } catch (error) {
            actions.setNotification({ type: 'error', message: 'Error deleting catalog' });
            actions.setLoading(false);
        }
    }),
    updateCatalogProducts: thunk(async (actions, info, { getStoreState }) => {
        try {
            actions.setLoading(true);
            await axios.request({
                url: `${constants.baseurl}/catalogs/${getStoreState()?.catalogSelected?.id}/products`,
                method: 'put',
                headers: {
                    'Authorization': `JWT ${getStoreState()?.auth?.token}`, // Encode credentials in Base64
                },
                data: info
            }).catch(error => {
                throw new Error(error.message);
            })
            actions.setNotification({ type: 'success', message: 'Catalog products updated successfully' });
            actions.fetchAllProductsFromProject()
            actions.setLoading(false);
        } catch (error) {
            actions.setNotification({ type: 'error', message: 'Error updating catalog products' });
            actions.setLoading(false);
        }
    }),
    updateCatalogProductImages: thunk(async (actions, info, { getStoreState }) => {
        try {
            actions.setLoading(true);
            await axios.request({
                url: `${constants.baseurl}/catalogs/${getStoreState()?.catalogSelected?.id}/products-images`,
                method: 'put',
                headers: {
                    'Authorization': `JWT ${getStoreState()?.auth?.token}`, // Encode credentials in Base64
                },
                data: info
            }).catch(error => {
                throw new Error(error.message);
            });
            actions.fetchAllImagesFromProject()
            actions.setLoading(false);
            actions.setNotification({ type: 'success', message: 'Catalog product images updated successfully' });
        } catch (error) {
            actions.setLoading(false);
            actions.setNotification({ type: 'error', message: 'Error updating catalog product images' });
        }
    }),
    replaceCatalogProducts: thunk(async (actions, info, { getStoreState }) => {
        try {
            actions.setLoading(true);
            await axios.request({
                url: `${constants.baseurl}/catalogs/${getStoreState()?.catalogSelected?.id}/products?replace=true`,
                method: 'put',
                headers: {
                    'Authorization': `JWT ${getStoreState()?.auth?.token}`, // Encode credentials in Base64
                },
                data: info
            }).catch(error => {
                throw new Error(error.message);
            })
            actions.setNotification({ type: 'success', message: 'Catalog products replaced successfully' });
            actions.fetchAllProductsFromProject()
            actions.setLoading(false);
        } catch (error) {
            actions.setLoading(false);
            actions.setNotification({ type: 'error', message: 'Error replacing catalog products' });
        }
    }),
    fetchAllAttributes: thunk(async (actions, catalog_id, { getStoreState }) => {
        try {
            actions.setLoading(true);
            const response = await axios.request({
                url: `${constants.baseurl}/catalogs/${catalog_id}/attributes`,
                method: 'GET',
                headers: {
                    'Authorization': `JWT ${getStoreState()?.auth?.token}`, // Encode credentials in Base64
                },
            }).catch(error => {
                throw new Error(error.message);
            })
            actions.setCatalogAttributes(response?.data?.data.map((elm, idx) => ({ ...elm, order: elm?.order || idx })));
            actions.setLoading(false);
        } catch (error) {
            actions.setLoading(false);
            actions.setNotification({ type: 'error', message: 'Error fetching catalog attributes' });
        }
    }),
    fetchAllAttributesByCatalog: thunk(async (actions, catalog_id, { getStoreState }) => {
        try {
            actions.setLoading(true);
            const response = await axios.request({
                url: `${constants.baseurl}/catalogs/${catalog_id}/attributes`,
                method: 'GET',
                headers: {
                    'Authorization': `JWT ${getStoreState()?.auth?.token}`, // Encode credentials in Base64
                },
            })
            actions.fetchAllImagesFromProject();
            actions.setAttributesByCatalog(response?.data?.data);
            actions.setLoading(false);
        } catch (error) {
            actions.setLoading(false);
            actions.setNotification({ type: 'error', message: 'Error fetching catalog attributes' });
        }
    }),
    createProductByCatalog: thunk(async (actions, data, { getStoreState }) => {
        try {
            actions.setLoading(true);
            await axios.request({
                url: `${constants.baseurl}/catalogs/${getStoreState()?.catalogSelected?.id}/products`,
                method: 'put',
                headers: {
                    'Authorization': `JWT ${getStoreState()?.auth?.token}`, // Encode credentials in Base64
                },
                data
            }).catch(error => {
                throw new Error(error.message);
            })
            actions.fetchAllProductsFromProject();
            actions.setProductSelected(null);
            actions.setLoading(false);
            actions.setShowFormProduct(false);
            actions.setNotification({ type: 'success', message: 'Product created successfully' });
        } catch (error) {
            actions.setLoading(false);
            actions.setNotification({ type: 'error', message: 'Error creating product' });
        }
    }),
    duplicateProduct: thunk(async (actions, productId, { getStoreState }) => {
        try {
            actions.setLoading(true);
            const catalogId = getStoreState()?.catalogSelected?.id;
            await axios.request({
                url: `${constants.baseurl}/catalogs/${catalogId}/products/${productId}/duplicate`,
                method: 'post',
                headers: {
                    'Authorization': `JWT ${getStoreState()?.auth?.token}`, // Encode credentials in Base64
                },
            }).catch(error => {
                throw new Error(error.message);
            })
            actions.fetchAllProductsFromProject();
            actions.setProductSelected(null);
            actions.setLoading(false);
            actions.setShowFormProduct(false);
        } catch (error) {
            actions.setLoading(false);
            actions.setNotification({ type: 'error', message: 'Error duplicating product' });
        }
    }),
    deleteProduct: thunk(async (actions, productId, { getStoreState }) => {
        try {
            actions.setLoading(true);
            const catalogId = getStoreState()?.catalogSelected?.id;
            await axios.request({
                url: `${constants.baseurl}/catalogs/${catalogId}/products/${productId}`,
                method: 'delete',
                headers: {
                    'Authorization': `JWT ${getStoreState()?.auth?.token}`, // Encode credentials in Base64
                },
            })
            actions.fetchAllProductsFromProject()
            actions.setLoading(false);
            actions.setShowFormProduct(false);
            actions.setProductSelected(null);
        } catch (error) {
            actions.setNotification({ type: 'error', message: 'Error deleting product' });
            actions.setLoading(false)
        }
    }),
    updateProductByCatalog: thunk(async (actions, data, { getStoreState }) => {
        try {
            actions.setLoading(true);
            const catalogId = getStoreState()?.catalogSelected?.id;

            await axios.request({
                url: `${constants.baseurl}/catalogs/${catalogId}/products/${data?.productId}`,
                method: 'put',
                headers: {
                    'Authorization': `JWT ${getStoreState()?.auth?.token}`, // Encode credentials in Base64
                },
                data
            }).catch(error => {
                throw new Error(error.message);
            })
            actions.fetchAllProductsFromProject();
            actions.setProductSelected(null);
            actions.setShowFormProduct(false);
            actions.setLoading(false);
            actions.setNotification({ type: 'success', message: 'Product by catalog updated successfully' });

        } catch (error) {
            actions.setNotification({ type: 'error', message: 'Error updating product by catalog' });
            actions.setLoading(false);
        }
    }),
    updateMultipleProductsByCatalog: thunk(async (actions, data, { getStoreState }) => {
        try {
            actions.setLoading(true);
            const catalogId = getStoreState()?.catalogSelected?.id;
            for (const product of data) {
                await axios.request({
                    url: `${constants.baseurl}/catalogs/${catalogId}/products/${product?.productId}`,
                    method: 'put',
                    headers: {
                        'Authorization': `JWT ${getStoreState()?.auth?.token}`, // Encode credentials in Base64
                    },
                    data: {
                        productId: undefined,
                        ...product
                    }
                }).catch(error => {
                    throw new Error(error.message);
                })
            }
            actions.setLoading(false);
            actions.setShowEnrich(false);
            actions.setPreviewProducts([]);
            actions.fetchAllProductsFromProject();

        } catch (error) {
            actions.setNotification({ type: 'error', message: 'Error updating product by catalog' });
            actions.setLoading(false);
        }
    })
};
export default thunks;
