import { LoadingButton } from '@mui/lab'
import { Box, Paper, TextField } from '@mui/material'
import { useStoreActions, useStoreState } from 'easy-peasy';
import React from 'react'
import { useForm } from 'react-hook-form';

export default function AuthForm() {
    const authenticate = useStoreActions(state => state.authenticate);
    const isAuthLoading = useStoreState(state => state.isAuthLoading);
    const { register, handleSubmit, formState: { errors } } = useForm();



    const onSubmit = (data) => {
        authenticate({
            username: data.username,
            password: data.password,
        });
    };
    return (
        <Paper elevation={6} sx={{ p: 3, borderRadius: 2, backgroundColor: 'white' }}>
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    label="Username"
                    autoComplete="username"
                    autoFocus
                    disabled={isAuthLoading}
                    {...register('username', { required: 'Username is required' })}
                    error={!!errors.username}
                    helperText={errors.username ? errors.username.message : ''}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    disabled={isAuthLoading}
                    {...register('password', { required: 'Password is required' })}
                    error={!!errors.password}
                    helperText={errors.password ? errors.password.message : ''}
                />
                <LoadingButton
                    loading={isAuthLoading}
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Login
                </LoadingButton>
            </Box>
        </Paper>
    )
}
