import moment from 'moment';
import utils from '../../helpers/utils'
import BackupIcon from '@mui/icons-material/Backup';
import { useStoreActions, useStoreState } from 'easy-peasy';
import PermMediaIcon from '@mui/icons-material/PermMediaTwoTone';
import MoreActionsImageCatalogTable from './MoreActionsImageCatalogTable';
import CloudCircleOutlinedIcon from '@mui/icons-material/CloudCircleOutlined';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturingTwoTone';
import { Button, IconButton, LinearProgress, ListItemIcon, Menu, MenuItem, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { DataGrid, GridToolbarFilterButton, GridToolbarColumnsButton, GridToolbarContainer } from '@mui/x-data-grid';
import { useEffect, useMemo, useState } from 'react';
import ImageStack from '../productsList/ImageStack';
import ImageIndividual from '../productsList/ImageIndividual';

export default function ImageCatalogTable() {

    const closedAllDrawers = useStoreActions(state => state.closedAllDrawers);
    const setShowRelateToProducts = useStoreActions(state => state.setShowRelateToProducts);
    const images = useStoreState(state => state.images);
    const imagesMode = useStoreState(state => state.imagesMode);
    const isLoading = useStoreState(state => state.loading);
    const imagesByProduct = useStoreState(state => state.imagesByProduct);
    const setShowUploadImages = useStoreActions(state => state.setShowUploadImages);
    const setImagesMode = useStoreActions(state => state.setImagesMode);
    const setImageSelected = useStoreActions(state => state.setImageSelected);


    const CustomToolbar = () => {
        const [anchorEl, setAnchorEl] = useState(null);
        const open = Boolean(anchorEl);
        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
            setAnchorEl(null);
        };
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <Button
                    startIcon={imagesMode === 'groupByImage' ? <PermMediaIcon /> : <PrecisionManufacturingIcon />}
                    onClick={handleClick}
                    aria-controls={open ? 'create-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    Group by {imagesMode === 'groupByImage' ? 'Image' : 'Product'}
                </Button>
                <Menu
                    id="create-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'lock-button',
                        role: 'listbox',
                    }}
                >
                    <MenuItem disabled={imagesMode === 'groupByImage'} onClick={(event) => { setImagesMode('groupByImage') }}>
                        <ListItemIcon>
                            <PermMediaIcon fontSize="small" />
                        </ListItemIcon>
                        By Image
                    </MenuItem>
                    <MenuItem disabled={imagesMode === 'groupByProduct'} onClick={(event) => { setImagesMode('groupByProduct') }}>
                        <ListItemIcon>
                            <PrecisionManufacturingIcon fontSize="small" />
                        </ListItemIcon>
                        By Product
                    </MenuItem>
                </Menu>
                <Button
                    startIcon={<BackupIcon />}
                    onClick={() => setShowUploadImages(true)}
                >
                    Upload Image(s)
                </Button>
                <span style={{ position: 'absolute', right: '15px', display: 'flex' }}>
                    <CloudCircleOutlinedIcon style={{ marginRight: '15px' }} /> Bucket Size: {utils.bytesToMegaBytes(images.reduce((a, b) => a + parseInt(b.size), 0))} MB</span>
            </GridToolbarContainer>
        );
    }


    let columns = useMemo(() => ([
        {
            field: 'image',
            headerName: 'Image',
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return <ImageIndividual params={params} />
            }
        },
        {
            field: 'id',
            type: 'number',
            headerName: 'ID',
        },

        {
            field: 'stored_name',
            headerName: 'Stored Name',
            minWidth: 230
        },
        {
            field: 'original_name',
            headerName: 'Original Name',
            minWidth: 125
        },
        {
            field: 'mimetype',
            headerName: 'Type',
            minWidth: 130
        },
        {
            field: 'size',
            headerName: 'Size',
            renderCell: (params) => <>{utils.bytesToMegaBytes(parseInt(params?.row.size))} MB</>,
        },
        {
            field: 'product_number',
            headerName: 'Products',
            renderCell: (params) => <div><PrecisionManufacturingIcon style={{ verticalAlign: 'middle' }} /> x {params?.row.product_number}</div>,
        },
        {
            field: 'created_at',
            headerName: 'Uploaded',
            sortable: true,
            minWidth: 150,
            type: 'dateTime',
            valueFormatter: (value) => new Date(value),
            renderCell: (params) => params.row.created_at ? moment(params.row.created_at).format('YYYY-MM-DD HH:mm') : 'XX',
        },
        {
            field: 'actions',
            headerName: 'Actions',
            type: 'actions',
            sortable: false,
            valueFormatter: (_) => '...',
            renderCell: (params) => !!params.row ? <MoreActionsImageCatalogTable image={params.row} /> : '',
        },
    ]), [])// eslint-disable-line

    let columnsByProduct = useMemo(() => ([

        {
            minWidth: 175,
            field: 'Product Image File Name',
            headerName: 'Image',
            renderCell: (params) => {
                return <ImageStack images_id={params.row.images_arr.map(i => i.id)} images={params.row.images_arr.map(i => i.stored_name)} width={params.colDef.computedWidth} />
            }
        },
        {
            field: 'name',
            minWidth: 150,
            headerName: 'Product',
            align: 'left',
            renderCell: (params) => {
                const product_designation = params.row.attribute_values?.id;
                return <>{!!product_designation ? product_designation : <CloseIcon style={{ fontSize: '20px', color: 'rgba(0, 0, 0, 0.38)', verticalAlign: 'middle' }} />}</>
            },
        },
        {
            field: 'images_arr',
            headerName: 'Images',
            minWidth: 350,
            renderCell: (params) => <div className="image-list-table">
                <b>[{params.row.images_arr.length} image(s)]</b>
                {params.row.images_arr.map((image, index) => (<div key={index}>
                    <span className="image-name-list">#{image.id} - {image.original_name} - {utils.bytesToMegaBytes(parseInt(image.size))} MB</span>
                    <hr className="hr-name-list"></hr>
                </div>))}
            </div>,
        },
        {
            field: 'product_created_at',
            headerName: 'Created At',
            sortable: true,
            minWidth: 150,
            type: 'dateTime',
            valueFormatter: (value) => new Date(value),
            renderCell: (params) => params.row.product_created_at ? moment(params.row.product_created_at).format('YYYY-MM-DD HH:mm') : <CloseIcon style={{ fontSize: '20px', color: 'rgba(0, 0, 0, 0.38)', verticalAlign: 'middle' }} />,
        },
        {
            field: 'product_updated_at',
            headerName: 'Updated At',
            sortable: true,
            minWidth: 150,
            type: 'dateTime',
            valueFormatter: (value) => new Date(value),
            renderCell: (params) => params.row.product_updated_at ? moment(params.row.product_updated_at).format('YYYY-MM-DD HH:mm') : <CloseIcon style={{ fontSize: '20px', color: 'rgba(0, 0, 0, 0.38)', verticalAlign: 'middle' }} />,
        },
        {
            field: 'actions',
            headerName: 'Actions',
            type: 'actions',
            sortable: false,
            valueFormatter: (_) => '...',
            renderCell: (params) => <Tooltip title="No actions available for images grouped by products"><span><IconButton disabled><MoreVertIcon /></IconButton></span></Tooltip>,
        },
    ]), [])// eslint-disable-line


    useEffect(() => {
        return () => {
            closedAllDrawers()
            setShowUploadImages(false)
        };
    }, [setShowUploadImages])// eslint-disable-line

    console.log(imagesByProduct)
    return (
        <div style={{ width: '100%' }}>
            <DataGrid
                autoHeight
                slots={{
                    toolbar: CustomToolbar,
                    loadingOverlay: LinearProgress,
                }}
                loading={isLoading}
                rows={imagesMode === 'groupByImage' ? images.map(column => ({
                    ...column,
                    flex: 1,
                    minWidth: 100,
                })) : imagesByProduct.filter(i => i.images_arr.length > 0).map(column => ({
                    ...column,
                    flex: 1,
                    minWidth: 100,
                }))}
                columns={imagesMode === 'groupByImage' ? columns : columnsByProduct}

                density='comfortable'
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                    }
                }}
                onRowSelectionModelChange={(row) => {
                    setImageSelected(images.find(i => i?.id === row[0]))
                }}
                onRowDoubleClick={() => {
                    setShowRelateToProducts(true);
                }}
                pageSizeOptions={[5, 10, 15]}
                checkboxSelection={false}
                hideFooterSelectedRowCount={true}
                disableMultipleRowSelection={true}
            />
        </div>
    )
}
