import React, { useEffect } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { ReactComponent as Logo } from '../../assets/icon.svg';
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import AuthForm from '../auth/authForm';

const particleOptions = {
  background: {
    color: {
      value: "#131313",
    },
  },
  fpsLimit: 120,
  interactivity: {
    modes: {
      push: {
        quantity: 4,
      },
      repulse: {
        distance: 200,
        duration: 0.4,
      },
    },
  },
  particles: {
    color: {
      value: "#ffffff",
    },
    links: {
      color: "#ffffff",
      distance: 150,
      enable: true,
      opacity: 0.5,
      width: 0.5,
    },
    move: {
      direction: "none",
      enable: true,
      outModes: {
        default: "bounce",
      },
      random: false,
      speed: 3,
      straight: false,
    },
    number: {
      density: {
        enable: true,
        area: 800,
      },
      value: 80,
    },
    opacity: {
      value: 0.5,
    },
    shape: {
      type: "circle",
    },
    size: {
      value: { min: 1, max: 3 },
    },
  },
  detectRetina: true,
};

export default function AuthContainer() {
  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    });
  }, []);

  const particlesLoaded = (container) => {
    console.log(container);
  };

  return (
    <>
      <Box
        sx={{
          minWidth: '400px',
          backgroundSize: 'cover',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'var(--primary-color)',
        }}
      >
        <Container component="main" maxWidth="xs" style={{ zIndex: 1000 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBlockEnd: '15px' }}>
            <Logo style={{ minWidth: '300px', height: '125px', display: { md: 'flex' } }} />
            <Typography component="h6" variant="h6" align="center" color={'white'}>
              Elevating your product catalogue
            </Typography>
          </Box>
          <AuthForm />
        </Container>
      </Box>
      <Particles
        id="tsparticles"
        particlesLoaded={particlesLoaded}
        options={particleOptions}
        style={{
          position: 'absolute',
          zIndex: -1,
          width: '100%',
          height: '100%',
        }}
      />
    </>
  );
}
