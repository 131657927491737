import { Alert, Container, Snackbar } from '@mui/material'
import { useStoreActions, useStoreState } from 'easy-peasy';
import IconBasedOnString from './common/IconBasedOnString';
import ProductsCatalogContainer from './containers/ProductsCatalogContainer';
import CatalogsContainer from './containers/CatalogsContainer';
import ImageCatalogContainer from './containers/ImageCatalogContainer';
import AttributesCatalogContainer from './containers/AttributesCatalogContainer';
import AuthContainer from './containers/AuthContainer';
import { useEffect } from 'react';

export default function Content() {
    const auth = useStoreState(state => state.auth);
    const tabSelected = useStoreState(state => state.navigation.tabSelected);
    const cleanNotification = useStoreActions(state => state.cleanNotification);
    const closedAllDrawers = useStoreActions(state => state.closedAllDrawers);
    const notification = useStoreState(state => state.notification);

    //closedAllDrawers by pressing ESC
    useEffect(() => {
        const handleEsc = (event) => {
            if (event.key === 'Escape') {
                closedAllDrawers()
            }
        };
        document.addEventListener('keydown', handleEsc);

        return () => {
            closedAllDrawers();
            document.removeEventListener('keydown', handleEsc);
        };
    }, [])// eslint-disable-line

    return (!auth ? (<AuthContainer />) : (
        <Container className="container-custom-styles" maxWidth="lg">
            {!auth && <AuthContainer />}
            {auth && <h1><IconBasedOnString designation={tabSelected} /> {tabSelected}</h1>}
            {auth && tabSelected === 'Catalog Images' && <ImageCatalogContainer />}
            {auth && tabSelected === 'Catalog Attributes' && <AttributesCatalogContainer />}
            {auth && tabSelected === 'Product Listings' && <ProductsCatalogContainer />}
            {auth && tabSelected === 'Catalog List' && <CatalogsContainer />}
            <Snackbar
                sx={{ marginBlockEnd: '1%' }}
                open={!!notification} autoHideDuration={3000} onClose={() => cleanNotification()}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                {notification ? <Alert
                    onClose={() => cleanNotification()}
                    severity={notification?.type}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {notification?.message}
                </Alert> : <div></div>}
            </Snackbar>
        </Container>)
    )

}
