import { Avatar } from '@mui/material';
import axios from 'axios';
import { useStoreState } from 'easy-peasy';
import React, { useEffect, useState } from 'react'
import constants from '../../helpers/constants';
export default function ImageIndividual({params}) {
    const [backgroundImage, setBackgroundImage] = useState('');
    const token = useStoreState(state => state.auth.token);
    useEffect(() => {
        const fetchImage = async () => {
        try {
            const response = await axios.get(`${constants.baseurl}/images/${params.row.stored_name}`, {
            headers: {
                Authorization: `JWT ${token}`
            },
            responseType: 'blob'
            });

            const imageUrl = URL.createObjectURL(response.data);
            setBackgroundImage(imageUrl);
        } catch (error) {
            console.error('Error fetching the image', error);
        }
        };

        fetchImage();
    // eslint-disable-next-line
    }, []);
    return <Avatar src={`${backgroundImage}`} variant="rounded" className='image-custom-styles' style={{ width: '100%', height: '100%', border: '0px', marginLeft: '-10px', borderRadius: '0px', borderRight: '1px solid rgb(224, 224, 224)' }} />
}
