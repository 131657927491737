import clone from 'fast-copy';
const initial_state = clone({
    //Application State
    navigation: {
        tabSelected: 'Authentication'
    },
    options: {
        version: '0.0.0'
    },
    //Data State
    fixes: [],
    images: [],
    catalogs: [],
    myCatalog: [],
    savedImages: [],
    products: [],
    imagesByProduct: [],
    catalogAttributes: [],
    previewProducts: [],
    previewFixes: [],
    imageSelected: null,
    catalogSelected: null,
    attributeSelected: null,
    productSelected: null,
    //Flags
    isAuthLoading: false,
    loading: false,
    isGenerateIALoading: false,
    isCorrectFixLoading: false,
    isEnrichLoading:false,
    showFormCatalog: false,
    showUploadImages: false,
    showSavedImages: false,
    loadingFormData: false,
    showSavedCatalog: false,
    showFormAttribute: false,
    showUploadAttribute: false,
    showUploadProducts: false,
    showRelateToProducts: false,
    showFormProduct: false,
    showGenerateIA: false,
    showCorrectFix: false,
    showEnrich: false,
    //Modes
    myCatalogMode: null,
    auth: null,
    imagesMode: 'groupByImage',
    notification: null,
})
export default initial_state;
