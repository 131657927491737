
import CatalogsTable from '../catalogList/CatalogsTable';
import { useStoreState } from 'easy-peasy';
import CalalogListDrawer from '../catalogList/CalalogListDrawer';

export default function CatalogsContainer() {

  const showFormCatalog = useStoreState(state => state.showFormCatalog);

  return (
    <div className='catalog-container-custom-styles'>
      <CatalogsTable />
      {showFormCatalog && <CalalogListDrawer />}
    </div >
  )
}
