import './App.css';
import INITIAL_STATE from './state'
import Content from './components/Content';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { StoreProvider, createStore, persist } from 'easy-peasy';
import TopDrawer from './components/containers/TopDrawer';
//Load store
const store = createStore(
  persist({
    ...INITIAL_STATE
  })
);

const theme = createTheme({
  typography: {
    fontFamily: '"Poppins", sans-serif',
  },
  palette: {
    primary: {
      main: '#131313',
      // main: '#0f0f0f',
      // main: '#262626',
    },
    secondary: {
      main: '#FFFFFF',
    },
  },
});

const App = () => {
  return (
    <StoreProvider store={store}>
      <ThemeProvider theme={theme}>
        <div className="App">
          <TopDrawer>
            <Content />
          </TopDrawer>
        </div>
      </ThemeProvider>
    </StoreProvider>

  );
}
export default App;
