import ExcelJS from 'exceljs';
import utils from '../../helpers/utils';
import { Box, Chip, Container, ImageListItem, ImageListItemBar, Tooltip, Typography } from "@mui/material";
import React, { useState } from 'react'
import CustomAutocompleteAttributes from '../common/CustomAutocompleteAttributes'
import { LoadingButton } from '@mui/lab'
import { Close } from "@mui/icons-material";
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useDropzone } from 'react-dropzone';
import InfoIcon from '@mui/icons-material/Info';
import constants from "../../helpers/constants";
import LayersClearIcon from '@mui/icons-material/LayersClear';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import PreviewTable from './PreviewTable';
import Lottie from 'react-lottie';
import animationData from '../../assets/loading-animation-4.json';
import SaveIcon from '@mui/icons-material/Save';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import ReplayIcon from '@mui/icons-material/Replay';
import IconButton from '@mui/material/IconButton';

function srcset(image, width, height, rows = 1, cols = 1) {
    return {
        src: `${constants.baseurl}/images/${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format`,
        srcSet: `${constants.baseurl}/images/${image}?w=${width * cols}&h=${height * rows
            }&fit=crop&auto=format&dpr=2 2x`,
    };
}
const defaultOptions = {
    loop: true, // Set to true for continuous animation
    autoplay: true, // Set to true for automatic playback
    animationData, // Replace with your animation data
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice' // Adjust aspect ratio as needed
    }
};
export default function GenerateIAContent() {
    const [previewImages, setPreview] = useState([]);
    const images = useStoreState(state => state.images);
    const loading = useStoreState(state => state.isGenerateIALoading);
    const [errorMessage, setErrorMessage] = useState(null);
    const previewProducts = useStoreState(state => state.previewProducts);
    const lastRequestDuration = useStoreState(state => state.lastRequestDuration);
    const catalogAttributes = useStoreState(state => state.catalogAttributes);
    const setShowGenerateIA = useStoreActions(state => state.setShowGenerateIA);
    const generateMyCatalog = useStoreActions(state => state.generateMyCatalog);
    const setPreviewProducts = useStoreActions(state => state.setPreviewProducts);
    const createProductByCatalog = useStoreActions(state => state.createProductByCatalog);
    const replaceCatalogProducts = useStoreActions(state => state.replaceCatalogProducts);
    const attributesAvailable = catalogAttributes.filter(i => !i.no_ia && !i.unique_code);
    const [attributesToGenerate, setAttributesToGenerate] = useState([...attributesAvailable]);


    const replaceProducts = () => {
        replaceCatalogProducts([...previewProducts]);
        setShowGenerateIA(false);
    }
    const addToProducts = () => {
        createProductByCatalog([...previewProducts]);
        setShowGenerateIA(false);
    }


    const { getRootProps, getInputProps } = useDropzone({
        onDrop: async (acceptedFiles) => {
            const unallowedType = acceptedFiles.filter(i => !i.type.includes('spreadsheet'));
            const overflowdImageSize = acceptedFiles.filter(i => utils.bytesToMegaBytes(i.size) > 4);
            //Error Handling
            if (acceptedFiles.length > 1) return setErrorMessage('Only one file of categories expected')
            if (unallowedType.length > 0) return setErrorMessage('Unexpected type of file uploaded');
            else if (overflowdImageSize.length > 0) return setErrorMessage('File uploaded must be below 4MB');
            else setErrorMessage(null);

            const setProductFromFile = async (fileUploaded) => {
                const workbook = new ExcelJS.Workbook();
                const arrayBuffer = await fileUploaded.arrayBuffer();
                await workbook.xlsx.load(arrayBuffer);
                const worksheetOutput = workbook.getWorksheet("Product Listing");

                if (!worksheetOutput) return setErrorMessage('Unexpected type of file uploaded');

                let outputFields = [];

                for (let row = 2; row <= worksheetOutput.rowCount; row++) {
                    let outputVariable = {};
                    for (let column = 1; column <= worksheetOutput.columnCount; column++) {
                        if (!['Product Index', 'id'].includes(worksheetOutput.getCell(1, column).value) && !!worksheetOutput.getCell(1, column).value) {
                            const cell = worksheetOutput.getCell(row, column);
                            const type = catalogAttributes?.find(i => i.name === worksheetOutput.getCell(1, column).value)?.type;
                            if (!type && worksheetOutput.getCell(1, column).value !== 'Product Image File Name') {
                                return setErrorMessage('The attributes found on the excel do not match the attributes in the catalog. Please update the catalog and try again.');
                            }
                            if (!!cell.value) {
                                if (type === 'Dropdown') outputVariable[worksheetOutput.getCell(1, column).value] = [cell.value];
                                else outputVariable[worksheetOutput.getCell(1, column).value] = cell.value;
                            }
                        }
                    }
                    if (!!outputVariable?.['Product Image File Name']) {
                        outputVariable['Product Image File Name'] = outputVariable?.['Product Image File Name'].split(',').map(i => images.find(x => x.original_name === i.trim())?.stored_name);
                        if(outputVariable['Product Image File Name'].filter(i => !i).length > 0) return setErrorMessage('Some images not found on the server. Please update the catalog and try again.');
                    }
                    else outputVariable['Product Image File Name'] = [];
                    outputFields.push(outputVariable)
                }
                setPreview(outputFields.map(i => !!i['Product Image File Name'] ? i['Product Image File Name'] : []))
            }

            setProductFromFile(acceptedFiles[0]);

        },
    });


    return (
        <Container maxWidth="sm">
            <Box display="flex" justifyContent="space-between" alignItems="center" position="relative" marginTop="5%">
                <Typography variant="h6" style={{ color: 'black' }}>AI Generate</Typography>
                {!loading && previewProducts.length > 0 &&
                    <Box display="flex" alignItems="center">
                        <Tooltip title="Regenerate products">
                            <IconButton loading={false} style={{}} size="medium" variant={"outlined"} onClick={() => { setPreviewProducts([]); generateMyCatalog({ attributesToGenerate, previewImages }); }} >
                                <ReplayIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Clear products generated">
                            <IconButton loading={false} style={{}} size="medium" variant={"outlined"} onClick={() => { setPreviewProducts([]) }} >
                                <LayersClearIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>}
            </Box>
            {loading && <div style={{ textAlign: 'center' }}>
                <Lottie options={defaultOptions} height={350} width={'100%'} />
                <span style={{ color: 'rgba(0, 0, 0, 0.6)', marginTop: '20px' }}>Waiting for product generation ...</span>
            </div>}
            <div></div>
            {!loading && previewProducts.length === 0 && <>
                <CustomAutocompleteAttributes attributesToGenerate={attributesToGenerate} setAttributesToGenerate={setAttributesToGenerate} attributesAvailable={attributesAvailable} />
                <Box justifyContent="space-between" alignItems="center" position="relative" marginTop="3%">
                    <div style={{ color: 'rgba(0, 0, 0, 0.6)' }}>Select Product(s) - Image(s) </div>
                    {previewImages.length === 0 && <><div style={{ marginTop: '5%' }}>
                        Template:
                        <div style={{ textAlign: 'left', marginBottom: '10px', display: 'inline-block' }}>
                            <Chip icon={<FilePresentIcon color="#61dafb" />} className="download-chip-custom-styles" label={'template_generate.xlsx'} clickable onClick={() => utils.downloadTable(catalogAttributes, [], {fileName:'template_generate.xlsx',hideAttributes:true, showJustImage:true})} />
                        </div>
                    </div>
                        <div className='drag-and-drop-custom-styles' {...getRootProps()} style={{ minHeight: '150px' }}>
                            <input {...getInputProps()} />
                            <p>Drag and drop file here or click to browse.</p>
                        </div></>}
                    {errorMessage && <div style={{ display: 'flex', marginTop: '10px' }}><InfoIcon />{errorMessage}</div>}
                    {previewImages.length > 0 && <Tooltip title="Clear Product(s) - Image(s)" >
                        <IconButton loading={false} style={{position:'absolute', right:'0px', marginTop:'-30px'}} size="small" variant={"outlined"} onClick={() => { setPreview([]) }} >
                            <LayersClearIcon />
                        </IconButton>
                    </Tooltip>}
                </Box>
                <div style={{ overflowY:'scroll', height:'60vh'}}>
                {!!previewImages && previewImages.map((item, index) => {
                    return <div key={index} style={{ marginTop: '1%' }}>
                        Product {index + 1}: <span style={{ color: 'rgba(0, 0, 0, 0.6)', right: '25px', fontSize: '11px' }}>[{!!item ? item.length : 0} image(s) found]</span>
                        <div style={{ border: '1px solid #ddd', marginBottom: '10px', borderRadius: '10px', height: '100px', padding: '2%' }}>
                            {item.map(image => (<ImageListItem key={image} className="preview-generate-image" style={{
                                border: '1px solid #e0e0e0',
                                height: '100%',
                                display: 'inline-block',
                                marginRight: '5px'
                            }}>
                                <img
                                    {...srcset(image)}
                                    alt={image}
                                    loading="lazy"
                                />
                                <ImageListItemBar
                                    style={{
                                        color: 'black',
                                        padding: '0px',
                                        fontSize: '10px'
                                    }}
                                    title={image.split('-')[1]}
                                    position="bottom"
                                />
                            </ImageListItem>))}
                        </div>
                    </div>
                })}
                </div>
            </>}
            {!loading && previewProducts.length > 0 && <>
                <PreviewTable products={previewProducts} />
                {lastRequestDuration && <div style={{ fontWeight: '300', mt: '20px' }}><b>Note:</b> Product Generation lasted {lastRequestDuration / 1000} seconds for {previewProducts.length} product(s)</div>}
            </>}
            <LoadingButton loading={loading} startIcon={<Close />} style={{ minWidth: '45%', marginTop: '20px', }} size="small" variant={"outlined"} onClick={() => { setShowGenerateIA(false) }} >Close</LoadingButton>
            {previewProducts.length === 0 && <LoadingButton loading={loading} onClick={() => { generateMyCatalog({ attributesToGenerate, previewImages }) }} startIcon={<AutoAwesomeIcon />} style={{ minWidth: '45%', marginTop: '20px', float: 'right' }} size="small" variant={"contained"} type="submit" >Generate Products</LoadingButton>}
            {previewProducts.length > 0 && <LoadingButton loading={loading} startIcon={<SaveIcon />} style={{ minWidth: '25%', marginTop: '20px', float: 'right', marginLeft: '10px' }} size="small" variant={"contained"} onClick={addToProducts} >Save</LoadingButton>}
            {previewProducts.length > 0 && <LoadingButton loading={loading} startIcon={<PublishedWithChangesIcon />} style={{ minWidth: '25%', marginTop: '20px', float: 'right' }} size="small" variant={"contained"} onClick={replaceProducts} >Replace</LoadingButton>}
        </Container>
    )
}
