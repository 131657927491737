import { useState } from "react";
import ExcelJS from 'exceljs';

import utils from '../../helpers/utils';
import { LoadingButton } from "@mui/lab";
import PreviewTable from "./PreviewTable";
import { Close } from "@mui/icons-material";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useDropzone } from 'react-dropzone';
import InfoIcon from '@mui/icons-material/Info';
import SaveIcon from '@mui/icons-material/Save';
import { Container, Tooltip, Typography } from "@mui/material";
import LayersClearIcon from '@mui/icons-material/LayersClear';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import DownloadLink from "../common/DownloadLink";


function UploadContaner() {
  const [errorMessage, setErrorMessage] = useState(null);
  const [hasNameDuplicate, setHasDuplicate] = useState(false);
  const [previewAttributes, setPreviewAttributes] = useState([]);

  const updateCatalog = useStoreActions(state => state.updateCatalog);
  const catalogAttributes = useStoreState(state => state.catalogAttributes);
  const setCatalogAttributes = useStoreActions(state => state.setCatalogAttributes);
  const setShowUploadAttribute = useStoreActions(state => state.setShowUploadAttribute);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (acceptedFiles) => {
      const unallowedType = acceptedFiles.filter(i => !i.type.includes('spreadsheet'));
      const overflowdImageSize = acceptedFiles.filter(i => utils.bytesToMegaBytes(i.size) > 4);
      //Error Handling
      if (acceptedFiles.length > 1) return setErrorMessage('Only one file of categories expected')
      if (unallowedType.length > 0) return setErrorMessage('Unexpected type of file uploaded');
      else if (overflowdImageSize.length > 0) return setErrorMessage('File uploaded must be below 4MB');
      else setErrorMessage(null);

      if (acceptedFiles.length === 1 && !errorMessage) {
        const setCategoriesFromFile = async (fileUploaded) => {
          try {
            const workbook = new ExcelJS.Workbook();
            const arrayBuffer = await fileUploaded.arrayBuffer();
            await workbook.xlsx.load(arrayBuffer);
            const worksheet = workbook.getWorksheet("Product Attributes");

            let cf = [];

            for (let column = 2; column <= worksheet.columnCount; column++) {
              let catalogVariable = {};
              catalogVariable.name = worksheet.getCell(1, column).value;
              catalogVariable.unique_code = worksheet.getCell(2, column).value === 'TRUE' ? true : false;
              catalogVariable.no_ia = worksheet.getCell(3, column).value === 'TRUE' ? true : false;
              catalogVariable.has_prompt = worksheet.getCell(4, column).value === 'TRUE' ? true : false;
              catalogVariable.prompt = !!worksheet.getCell(5, column).value ? worksheet.getCell(5, column).value : `Add ${catalogVariable.name} in a few words`;
              catalogVariable.type = worksheet.getCell(6, column).value;
              catalogVariable.saved = true;
              catalogVariable.options = [];
              if (catalogVariable.type === 'Dropdown') {
                for (let row = 7; row <= worksheet.rowCount; row++) {
                  const cellValue = worksheet.getCell(row, column).value;
                  catalogVariable.options.push(cellValue);
                }
              }
              cf.push(catalogVariable)
            }
            console.log(cf)
            if (cf.find(i => !i || !i?.name || !i?.type)) return setErrorMessage('Invalid excel template used')
            setPreviewAttributes(cf)
            setHasDuplicate(!!cf.map(attr => (!!catalogAttributes.find(i => i.name === attr.name))).find(i => !!i))
          } catch (err) {
            console.error('Error reading spreadsheet:', err);
          }
        };

        setCategoriesFromFile(acceptedFiles[0])
      }

    },
  });

  const saveAttributes = () => {
    setCatalogAttributes([...catalogAttributes, ...previewAttributes])
    updateCatalog({ attributes: [...catalogAttributes, ...previewAttributes] })
  }
  const replaceAttributes = () => {
    setCatalogAttributes(previewAttributes)
    updateCatalog({ attributes: previewAttributes })
  }

  return (
    <Container maxWidth="sm">
      <Typography variant="h6" style={{ color: 'black', marginTop: '5%' }}>{previewAttributes.length > 0 ? 'Preview' : 'Upload'} Excel Template</Typography>
      {previewAttributes.length > 0 && <Tooltip title="Clear catalog attributes previewed"><LoadingButton loading={false} style={{ marginTop: '-30px', position: 'absolute', right: '25px' }} size="small" variant={"outlined"} onClick={() => { setPreviewAttributes([]); setHasDuplicate(false) }} ><LayersClearIcon /></LoadingButton></Tooltip>}
      {previewAttributes.length === 0 && <>
        <div style={{ marginTop: '5%' }}>
          Template: <DownloadLink fileName='template.xlsx' />
        </div>
        <div style={{ marginTop: '5%' }}>
          <div className='drag-and-drop-custom-styles' {...getRootProps()}>
            <input {...getInputProps()} />
            <p>Drag and drop file here or click to browse.</p>
          </div>
          {errorMessage && <div style={{ display: 'flex' }}><InfoIcon />{errorMessage}</div>}
        </div>
      </>}
      {previewAttributes.length > 0 && <PreviewTable catalogAttributes={previewAttributes} />}
      {hasNameDuplicate && <div style={{ display: 'flex', marginTop: '5%', textAlign: 'justify', fontSize: '12px' }}><InfoOutlinedIcon style={{ marginRight: '5px' }} /> There are duplicate names in your attributes please remove them to save</div>}
      <LoadingButton loading={false} startIcon={<Close />} style={{ minWidth: '45%', marginTop: '20px', }} size="small" variant={"outlined"} onClick={() => { setPreviewAttributes([]); setShowUploadAttribute(false) }} >Close</LoadingButton>
      {previewAttributes.length > 0 &&
        <Tooltip title={!hasNameDuplicate && <div style={{ display: 'flex' }}><InfoOutlinedIcon style={{ marginRight: '5px' }} /> By clicking "Save" the attributes previewed will automatically be added at the end of the list of the preexisting attributes of the catalog</div>}>
          <LoadingButton disabled={hasNameDuplicate} loading={false} startIcon={<SaveIcon />} style={{ minWidth: '25%', marginTop: '20px', float: 'right', marginLeft: '10px' }} size="small" variant={"contained"} onClick={() => { saveAttributes(); setShowUploadAttribute(false) }} >Save</LoadingButton>
        </Tooltip>
      }
      {previewAttributes.length > 0 && <LoadingButton loading={false} startIcon={<PublishedWithChangesIcon />} style={{ minWidth: '25%', marginTop: '20px', float: 'right' }} size="small" variant={"contained"} onClick={() => { replaceAttributes(); setShowUploadAttribute(false) }} >Replace</LoadingButton>}
    </Container>
  );
};

export default UploadContaner;