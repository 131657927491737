import { Controller, useForm } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import { useMemo } from "react";
import { Box, Container, FormControl, TextField, Tooltip, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from '@mui/icons-material/Save';
import { useStoreActions, useStoreState } from "easy-peasy";
import { Close } from "@mui/icons-material";
import SaveAsIcon from '@mui/icons-material/SaveAs';
import ContentCopyTwoToneIcon from '@mui/icons-material/ContentCopyTwoTone';
import FormHelperTextCustom from "../common/FormHelperTextCustom";
import IconButton from '@mui/material/IconButton';


function CatalogForm({ catalogSelected }) {
  const createCatalog = useStoreActions(state => state.createCatalog);
  const updateCatalog = useStoreActions(state => state.updateCatalog);
  const setShowFormCatalog = useStoreActions(state => state.setShowFormCatalog);
  const loading = useStoreState(state => state.loading);

  const { control, handleSubmit, formState: { errors }, } = useForm({
    defaultValues: {
      ...useMemo(
        () =>
        ({
          name: catalogSelected ? catalogSelected.name : "",
          owner: catalogSelected ? catalogSelected.owner : "",
          comments: catalogSelected ? catalogSelected.comments : "",
        }
        ),
        [catalogSelected]
      ),
    },
    mode: "onChange",
  });


  const onSubmitOnHandler = (formData) => {
    if (!catalogSelected) createCatalog(formData);
    if (!!catalogSelected) updateCatalog(formData)
  };


  return (
    <Container maxWidth="sm">
      <form onSubmit={handleSubmit(onSubmitOnHandler)}>
        <Box display="flex" justifyContent="space-between" alignItems="center" position="relative" marginTop="5%">
          <Typography variant="h6" style={{ color: 'black', }}>{!!catalogSelected ? `Edit Catalog #${catalogSelected.id}` : "Create Catalog"}</Typography>
          {!!catalogSelected && <Tooltip title="Duplicate">
            <IconButton
              size="medium"
              disabled={loading}
              loading={loading}
              variant={"outlined"}
              onClick={() => { setShowFormCatalog(false); createCatalog({ ...catalogSelected, name: `${catalogSelected.name} (1)`, id: undefined, result: undefined, images: undefined }); }} >
              <ContentCopyTwoToneIcon />
            </IconButton></Tooltip>
          }
        </Box>
        <Controller
          name={'name'}
          rules={{ required: true }}
          control={control}
          render={({ field: { ref, ...field } }) => (
            <FormControl {...field} error={!!errors?.name} style={{ width: '100%', }}>
              <TextField
                {...field}
                error={!!errors?.name}
                label={'Name'}
                disabled={loading}
                style={{ width: '100%', marginTop: '5%' }}
                variant={'filled'}
              />
              <FormHelperTextCustom key={`CF-errors.name`} showHelper={!!errors?.name} />
            </FormControl>
          )}
        />
        <Controller
          name={'owner'}
          rules={{ required: true }}
          control={control}
          render={({ field: { ref, ...field } }) => (
            <FormControl {...field} error={!!errors?.owner} style={{ width: '100%', }}>
              <TextField
                {...field}
                error={!!errors?.owner}
                disabled={loading}
                label={'Owner'}
                style={{ width: '100%', marginTop: '5%' }}
                variant={'filled'}
              />
              <FormHelperTextCustom key={`CF-errors.owner`} showHelper={!!errors?.owner} />
            </FormControl>
          )}
        />
        <Controller
          name={'comments'}
          control={control}
          render={({ field: { ref, ...field } }) => (
            <TextField
              {...field}
              disabled={loading}
              label={'Comments'}
              multiline
              rows={4}
              style={{ width: '100%', marginTop: '5%' }}
              variant={'filled'}
            />
          )}
        />
        <LoadingButton loading={loading} startIcon={<Close />} style={{ minWidth: '45%', marginTop: '20px', }} size="small" variant={"outlined"} onClick={() => { setShowFormCatalog(false) }} >Close</LoadingButton>
        <LoadingButton loading={loading} startIcon={catalogSelected ? <SaveAsIcon /> : <SaveIcon />} style={{ minWidth: '45%', marginTop: '20px', float: 'right' }} size="small" variant={"contained"}
          type="submit" >{!catalogSelected ? 'Create' : 'Save'}</LoadingButton>
      </form>

      <DevTool control={control} /> {/* set up the dev tool */}
    </Container >
  );
};

export default CatalogForm;