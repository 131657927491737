import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import ProductForm from './ProductForm';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useEffect } from 'react';
import { drawerFormWidth } from '../containers/TopDrawer';

export default function ProductListDrawer() {

	const fetchAllAttributesByCatalog = useStoreActions(state => state.fetchAllAttributesByCatalog);
	const loadingFormData = useStoreState(state => state.loadingFormData);
	const setLoadingFormData = useStoreActions(state => state.setLoadingFormData);
	const showFormProduct = useStoreState(state => state.showFormProduct);
	const catalogSelected = useStoreState(state => state.catalogSelected);

	useEffect(() => {
		fetchAllAttributesByCatalog(catalogSelected.id);
	}, [])// eslint-disable-line

	useEffect(() => {
		if (loadingFormData) {
			setLoadingFormData(false);
		}
		// eslint-disable-next-line
	}, [loadingFormData, setLoadingFormData])

	return (
		<Box sx={{ display: 'flex' }}>
			<Drawer
				sx={{
					width: drawerFormWidth,
					flexShrink: 0,
					'& .MuiDrawer-paper': {
						width: drawerFormWidth,
						top: "64px", // Subtract width of header
						// backgroundColor: 'beige'
					},
				}}
				variant="persistent"
				anchor="right"
				open={showFormProduct}
			>
				{(!loadingFormData && catalogSelected?.attributes?.length > 0) ? <ProductForm /> : <>There is no attribute associated with the selected catalogue, please create the attributes here</>}
			</Drawer>
		</Box>
	);
}
