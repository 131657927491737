import { useStoreState } from 'easy-peasy';
import React from 'react'
import ProductsCatalogTable from '../productsList/ProductsCatalogTable';
import ProductsCatalogUpload from '../productsList/ProductsCatalogUpload';
import ProductListDrawer from '../productsList/ProductListDrawer';
import GenerateIADrawer from '../productsList/GenerateIADrawer';
import CorrectFixDrawer from '../productsList/CorrectFixDrawer';
import EnrichDrawer from '../productsList/EnrichDrawer';

export default function MyCatalogContainer() {

  const showEnrich = useStoreState(state => state.showEnrich);
  const showGenerateIA = useStoreState(state => state.showGenerateIA);
  const showCorrectFix = useStoreState(state => state.showCorrectFix);
  const showFormProduct = useStoreState(state => state.showFormProduct);
  const showUploadProducts = useStoreState(state => state.showUploadProducts);

  return (
    <div className='my-catalog-container-custom-styles'>
      <ProductsCatalogTable />
      {showUploadProducts && <ProductsCatalogUpload />}
      {showFormProduct && <ProductListDrawer />}
      {showGenerateIA && <GenerateIADrawer />}
      {showCorrectFix && <CorrectFixDrawer />}
      {showEnrich && <EnrichDrawer />}
    </div>
  )
}
