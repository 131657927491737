import utils from '../../helpers/utils';
import CloseIcon from '@mui/icons-material/Close';
import { createSvgIcon } from '@mui/material/utils';
import BackupIcon from '@mui/icons-material/Backup';
import { useEffect, useMemo, useState } from 'react';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import MoreActionsAttributesCatalogTable from '../attributesList/MoreActionsAttributesCatalogTable';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { Box, Button, Chip, ListItemIcon, Menu, MenuItem, Select } from '@mui/material';
import { useStoreActions, useStoreState } from 'easy-peasy';
import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';


const ExportIcon = createSvgIcon(
    <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
    'SaveAlt',
);

let signalUpdateAttributesCatalogController;

export default function AttributesCatalogTable() {

    const loading = useStoreState(state => state.loading);
    const attributeSelected = useStoreState(state => state.attributeSelected);
    const updateAttributesCatalog = useStoreActions(state => state.updateAttributesCatalog);
    const catalogAttributes = useStoreState(state => state.catalogAttributes);
    const setShowSavedCatalog = useStoreActions(state => state.setShowSavedCatalog);
    const setCatalogAttributes = useStoreActions(state => state.setCatalogAttributes);
    const setShowUploadAttribute = useStoreActions(state => state.setShowUploadAttribute);
    const setAttributeSelected = useStoreActions(state => state.setAttributeSelected);
    const setShowFormAttribute = useStoreActions(state => state.setShowFormAttribute);
    const catalogWithIndex = catalogAttributes.map((i, index) => { return { ...i, position: index + 1 } });

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const buttonBaseProps = {
        color: 'primary',
        size: 'small',
        startIcon: <ExportIcon />,
    };
    const startEmptySelection = () => {
        setCatalogAttributes([]);
        setShowSavedCatalog(true)
    }

    useEffect(() => {
        return () => {
            setShowUploadAttribute(false)
            setShowFormAttribute(false)
        };
    }, [setShowUploadAttribute, setShowFormAttribute])

    const columns = useMemo(() => ([
        {
            accessorKey: 'name',
            header: 'Name',
            enablePinning: false,
        },
        {
            accessorKey: 'unique_code',
            header: 'Is Identifier',
            Cell: ({ cell }) => cell.getValue() ? 'Yes' : 'No',
        },
        {
            accessorKey: 'no_ia',
            header: 'Dont Generate',
            Cell: ({ cell }) => cell.getValue() ? 'Yes' : 'No',
        },
        {
            accessorKey: 'type',
            header: 'Type',
            Cell: ({ cell }) => <Chip label={cell.getValue()} variant="outlined" />,
        },
        {
            accessorKey: 'prompt',
            header: 'Prompt',
            Cell: ({ row }) => row.original.has_prompt && row.original.type === 'Text Field' ? row.original.prompt : <CloseIcon style={{ fontSize: '20px', color: 'rgba(0, 0, 0, 0.38)', verticalAlign: 'middle' }} />,
        },
        {
            accessorKey: 'options',
            header: 'Options',
            Cell: ({ row }) => row.original.type === 'Dropdown' ? (
                <Select value={row.original.options[0]} className="custom-select">
                    {row.original.options.map((option, index) => (
                        <MenuItem disabled key={index} value={option}>{option}</MenuItem>
                    ))}
                </Select>
            ) : <CloseIcon style={{ fontSize: '20px', color: 'rgba(0, 0, 0, 0.38)', verticalAlign: 'middle' }} />,
        },
    ]), []); // eslint-disable-line

    const [data, setData] = useState(catalogAttributes);

    const table = useMaterialReactTable({
        autoResetPageIndex: false,
        columns,
        data,
        enableRowOrdering: true,
        muiRowDragHandleProps: ({ table }) => ({
            onDragEnd: () => {
                const { draggingRow, hoveredRow } = table.getState();
                if (hoveredRow && draggingRow) {
                    data.splice(
                        hoveredRow.index,
                        0,
                        data.splice(draggingRow.index, 1)[0],
                    );
                    const newCatalogAttributes = data.map((elm, ind) => ({ ...elm, order: (ind) }));
                    if (JSON.stringify(catalogAttributes) !== JSON.stringify(newCatalogAttributes)) {
                        signalUpdateAttributesCatalogController && signalUpdateAttributesCatalogController.abort();
                        signalUpdateAttributesCatalogController = new AbortController();
                        updateAttributesCatalog({ payload: { attributes: newCatalogAttributes }, abortController: signalUpdateAttributesCatalogController });
                    }
                    setData([...data]);
                }
            },
        }),

        layoutMode: 'semantic', //constant column widths
        enableColumnOrdering: false,
        enableGrouping: false,
        enableColumnPinning: true,
        enableRowActions: true,
        enableStickyHeader: true,
        enableStickyFooter: true,
        enableKeyboardShortcuts: false,
        enableColumnActions: false,
        enableColumnFilters: false,
        enablePagination: true,
        enableSorting: false,
        enableFullScreenToggle: false,
        initialState: {
            showProgressBars: loading,
            showColumnFilters: false,
            showGlobalFilter: false,
            columnPinning: {
                left: ['mrt-row-drag',],
                right: ['mrt-row-actions'],
            },
        },
        columnFilterDisplayMode: 'subheader',
        paginationDisplayMode: 'pages',
        positionToolbarAlertBanner: 'bottom',
        renderRowActions: ({ row }) => (
            <MoreActionsAttributesCatalogTable attribute={row.original} catalogWithIndex={catalogWithIndex} />
        ),
        renderTopToolbarCustomActions: ({ table }) => (
            <Box
                sx={{
                    display: 'flex',
                    gap: '8px',
                    padding: '4px',
                    flexWrap: 'wrap',
                }}
            >
                <Button
                    {...buttonBaseProps}
                    onClick={() => utils.downloadTable(catalogWithIndex, null, { hideProducts: true, hideInstructions: true })}
                >
                    Export XLSX
                </Button>
                <Button
                    startIcon={<AddCircleOutlineOutlinedIcon />}
                    onClick={handleClick}
                    aria-controls={open ? 'create-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    Create New
                </Button>
                <Menu
                    id="create-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'lock-button',
                        role: 'listbox',
                    }}
                >
                    <MenuItem onClick={(event) => { setAttributeSelected(null); setShowFormAttribute(true); setShowUploadAttribute(false); handleClose() }}>
                        <ListItemIcon>
                            <AddCircleOutlineOutlinedIcon fontSize="small" />
                        </ListItemIcon>
                        New Attribute
                    </MenuItem>
                    <MenuItem onClick={(event) => startEmptySelection()}>
                        <ListItemIcon>
                            <HistoryEduIcon fontSize="small" />
                        </ListItemIcon>
                        Start Empty
                    </MenuItem>
                    <MenuItem onClick={(event) => { setShowUploadAttribute(true); setShowFormAttribute(false); }}>
                        <ListItemIcon>
                            <BackupIcon fontSize="small" />
                        </ListItemIcon>
                        Upload Excel Template
                    </MenuItem>
                </Menu>
            </Box>
        ),
        muiTableBodyRowProps: ({ row }) => {
            return {
                onClick: () => {
                    setAttributeSelected(catalogAttributes.find(i => i.name === row.original.name))
                },
                onDoubleClick: () => {
                    setShowFormAttribute(true)
                },
                sx: {
                    cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                    backgroundColor: `${attributeSelected && attributeSelected.name === row.original.name ? 'rgba(252, 252, 252, 0.9)' : 'inherit'}`,
                },
            }
        },
    });

    //update data when catalogAttributes changes
    useEffect(() => {
        setData(catalogAttributes);
    }, [catalogAttributes])

    return (
        <Box
            sx={{
                display: 'grid',
                gap: '1rem',
                overflow: 'auto',
                p: '4px',
            }}
        >
            <MaterialReactTable
                table={table}
            />
        </Box>
    );
}
