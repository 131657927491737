import { action } from 'easy-peasy';
const actions = {
    setApplicationOptions: action((state, options) => {
        state.options = options;
    }),
    setTabSelected: action((state, tabSelected) => {
        state.navigation.tabSelected = tabSelected;
        state.showFormAttribute = false;
        state.showUploadAttribute = false;
        state.showFormCatalog = false;
    }),
    setSavedImages: action((state, savedImages) => {
        state.savedImages = savedImages;
    }),
    setAuth: action((state, auth) => {
        state.auth = auth;
        state.catalogSelected = null;
    }),
    setLastRequestDuration: action((state, lastRequestDuration) => {
        state.lastRequestDuration = lastRequestDuration;
    }),
    setShowSavedCatalog: action((state, showSavedCatalog) => {
        state.showSavedCatalog = showSavedCatalog;
    }),
    setCatalogAttributes: action((state, catalogAttributes) => {
        state.catalogAttributes = catalogAttributes;
    }),
    setShowSavedImages: action((state, showSavedImages) => {
        state.showSavedImages = showSavedImages;
    }),
    setLoading: action((state, loading) => {
        state.loading = loading;
    }),
    setMyCatalog: action((state, myCatalog) => {
        state.myCatalog = myCatalog;
    }),
    setFixes: action((state, fixes) => {
        state.fixes = fixes;
    }),
    setCatalogs: action((state, catalogs) => {
        state.catalogs = catalogs;
    }),
    setMyCatalogMode: action((state, myCatalogMode) => {
        state.myCatalogMode = myCatalogMode;
    }),
    setImageSelected: action((state, imageSelected) => {
        state.imageSelected = imageSelected;
    }),
    setShowUploadAttribute: action((state, showUploadAttribute) => {
        state.showUploadAttribute = showUploadAttribute;
    }),
    setShowUploadImages: action((state, showUploadImages) => {
        state.showUploadImages = showUploadImages;
    }),
    setShowUploadProducts: action((state, showUploadProducts) => {
        state.showUploadProducts = showUploadProducts;
        if (showUploadProducts) {
            state.showEnrich = false;
            state.showCorrectFix = false;
            state.showGenerateIA = false;
            state.showFormProduct = false;
        }
    }),
    showFormProduct: action((state, showFormProduct) => {
        state.showFormProduct = showFormProduct;
        if (showFormProduct) {
            state.showEnrich = false;
            state.showCorrectFix = false;
            state.showGenerateIA = false;
            state.showUploadProducts = false;
        }
    }),
    setCatalogSelected: action((state, catalogSelected) => {
        state.catalogSelected = catalogSelected;
    }),
    setShowFormCatalog: action((state, showFormCatalog) => {
        state.showFormCatalog = showFormCatalog;
    }),
    setShowFormAttribute: action((state, showFormAttribute) => {
        state.showFormAttribute = showFormAttribute;
        state.loadingFormData = true;
    }),
    setIsGenerateIALoading: action((state, isGenerateIALoading) => {
        state.isGenerateIALoading = isGenerateIALoading;
    }),
    setIsCorrectFixLoading: action((state, isCorrectFixLoading) => {
        state.isCorrectFixLoading = isCorrectFixLoading;
    }),
    setIsEnrichLoading: action((state, isEnrichLoading) => {
        state.isEnrichLoading = isEnrichLoading;
    }),
    setAttributeSelected: action((state, attributeSelected) => {
        state.attributeSelected = attributeSelected;
    }),
    setLoadingFormData: action((state, loadingFormData) => {
        state.loadingFormData = loadingFormData;
    }),
    setShowRelateToProducts: action((state, showRelateToProducts) => {
        state.showRelateToProducts = showRelateToProducts;
    }),
    setShowGenerateIA: action((state, showGenerateIA) => {
        state.showGenerateIA = showGenerateIA;
        if (showGenerateIA) {
            state.showEnrich = false;
            state.showCorrectFix = false;
            state.showFormProduct = false;
            state.showUploadProducts = false;
        }
    }),
    setShowCorrectFix: action((state, showCorrectFix) => {
        state.showCorrectFix = showCorrectFix;
        if (showCorrectFix) {
            state.showEnrich = false;
            state.showGenerateIA = false;
            state.showFormProduct = false;
            state.showUploadProducts = false;
        }
    }),
    setShowEnrich: action((state, showEnrich) => {
        state.showEnrich = showEnrich;
        if (showEnrich) {
            state.showCorrectFix = false;
            state.showGenerateIA = false;
            state.showFormProduct = false;
            state.showUploadProducts = false;
        }
    }),
    //New
    setImages: action((state, images) => {
        state.images = images;
    }),
    setPreviewProducts: action((state, previewProducts) => {
        state.previewProducts = previewProducts;
    }),
    setImagesByProduct: action((state, imagesByProduct) => {
        state.imagesByProduct = imagesByProduct;
    }),
    setProducts: action((state, products) => {
        state.products = products;
    }),
    setPreviewFixes: action((state, previewFixes) => {
        state.previewFixes = previewFixes;
    }),
    setImagesMode: action((state, imagesMode) => {
        state.imagesMode = imagesMode;
    }),
    setAttributesByCatalog: action((state, attributes) => {

        state.catalogSelected = {
            ...state.catalogSelected,
            attributes
        };
    }),
    setShowFormProduct: action((state, showFormProduct) => {
        state.showFormProduct = showFormProduct;
    }),
    setProductSelected: action((state, productSelected) => {
        state.showFormProduct = true;
        state.loadingFormData = true;
        state.productSelected = productSelected;
    }),
    setNotification: action((state, notification) => {
        state.notification = notification;
    }),
    cleanNotification: action((state) => {
        state.notification = null;
    }),
    setisAuthLoading: action((state, isAuthLoading) => {
        state.isAuthLoading = isAuthLoading;
    }),
    closedAllDrawers: action((state) => {
        state.showFormCatalog = false;
        state.showUploadImages = false;
        state.showSavedImages = false;
        state.showSavedCatalog = false;
        state.showFormAttribute = false;
        state.showUploadAttribute = false;
        state.showUploadProducts = false;
        state.showRelateToProducts = false;
        state.showFormProduct = false;
        state.showGenerateIA = false;
        state.showCorrectFix = false;
        state.showEnrich = false;
        state.loading = false;
    }),
};
export default actions;
