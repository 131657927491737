import React from 'react';
import FlakyIcon from '@mui/icons-material/Flaky';
import FeedTwoToneIcon from '@mui/icons-material/FeedTwoTone';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import BallotTwoToneIcon from '@mui/icons-material/BallotTwoTone';
import SecurityTwoToneIcon from '@mui/icons-material/SecurityTwoTone';
import PermMediaTwoToneIcon from '@mui/icons-material/PermMediaTwoTone';
import CollectionsBookmarkTwoToneIcon from '@mui/icons-material/CollectionsBookmarkTwoTone';


export default function IconBasedOnString({ designation }) {
    switch (designation) { 
        case'Enrich':
            return <AutoFixHighIcon />
        case'Correct/Fix':
            return <FlakyIcon />
        case'Product Listings':
            return <FeedTwoToneIcon />
        case'Authentication':
            return <SecurityTwoToneIcon />
        case'Catalog Images':
            return <PermMediaTwoToneIcon />
        case'Catalog Attributes':
            return <CollectionsBookmarkTwoToneIcon />
        case'Catalog List':
            return <BallotTwoToneIcon />    
        default:
            return <>No icon</>
    }
}
