import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton } from '@mui/x-data-grid';
import { useStoreActions, useStoreState } from 'easy-peasy';
import React, { useEffect, useState } from 'react'
import { createSvgIcon } from '@mui/material/utils';
import BackupIcon from '@mui/icons-material/Backup';
import { Button, IconButton, LinearProgress, ListItemIcon, Menu, MenuItem, Tooltip } from '@mui/material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import ImageStack from './ImageStack';
import MoreActionsTable from './MoreActionsTable';
import NearbyErrorIcon from '@mui/icons-material/NearbyError';
import ClearIcon from '@mui/icons-material/Clear';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import InfoIcon from '@mui/icons-material/Info';
import utils from '../../helpers/utils';
import moment from 'moment';


const capitalize = s => (s && s[0].toUpperCase() + s.slice(1)) || ""
const ExportIcon = createSvgIcon(
  <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
  'SaveAlt',
);
export default function ProductCatalogTable() {

  const closedAllDrawers = useStoreActions(state => state.closedAllDrawers);
  const fixes = useStoreState(state => state.fixes);
  const products = useStoreState(state => state.products);
  const isLoading = useStoreState(state => state.loading);
  const setFixes = useStoreActions(state => state.setFixes);
  const setProducts = useStoreActions(state => state.setProducts);
  const setShowEnrich = useStoreActions(state => state.setShowEnrich);
  const catalogAttributes = useStoreState(state => state.catalogAttributes);
  const setShowCorrectFix = useStoreActions(state => state.setShowCorrectFix);
  const setShowGenerateIA = useStoreActions(state => state.setShowGenerateIA);
  const setProductSelected = useStoreActions(state => state.setProductSelected);
  const setPreviewProducts = useStoreActions(state => state.setPreviewProducts);
  const setShowUploadProducts = useStoreActions(state => state.setShowUploadProducts);
  const fetchAllImagesFromProject = useStoreActions(state => state.fetchAllImagesFromProject);
  const fetchAllProductsFromProject = useStoreActions(state => state.fetchAllProductsFromProject);



  const buttonBaseProps = {
    color: 'primary',
    size: 'small',
    startIcon: <ExportIcon />,
  };
  const CustomToolbar = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <Button
          {...buttonBaseProps}
          onClick={() => utils.downloadTable(catalogAttributes, products, {hideInstructions:true, hideAttributes:true})}
          disabled={products.length === 0 || isLoading}
        >
          Export XLSX
        </Button>
        <Button
          startIcon={<AddCircleOutlineOutlinedIcon />}
          onClick={handleClick}
          aria-controls={open ? 'create-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          Create New
        </Button>
        <Menu
          id="create-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'lock-button',
            role: 'listbox',
          }}
        >
          <MenuItem onClick={(event) => { closedAllDrawers(); setProductSelected(null); handleClose(); }}>
            <ListItemIcon>
              <AddCircleOutlineOutlinedIcon fontSize="small" />
            </ListItemIcon>
            New Product
          </MenuItem>
          <MenuItem onClick={(event) => { closedAllDrawers(); setShowUploadProducts(true) }}>
            <ListItemIcon>
              <BackupIcon fontSize="small" />
            </ListItemIcon>
            Upload Excel Template
          </MenuItem>
          <MenuItem onClick={(event) => { closedAllDrawers(); setShowGenerateIA(true) }}>
            <ListItemIcon>
              <AutoAwesomeIcon fontSize="small" />
            </ListItemIcon>
            AI Generate (from images)
          </MenuItem>
        </Menu>
        <Button
          disabled={isLoading || products.length === 0}
          startIcon={<NearbyErrorIcon />}
          onClick={() => { closedAllDrawers(); setShowCorrectFix(true) }}
        >
          Correct/Fix
        </Button>
        {
          fixes.length > 0 && <Button
            startIcon={<ClearIcon />}
            onClick={() => { closedAllDrawers(); setFixes([]) }}
          >
            Clear fixes
          </Button>
        }
        <Button
          disabled={isLoading || products.length === 0}
          startIcon={<AutoFixHighIcon />}
          onClick={() => { closedAllDrawers(); setShowEnrich(true) }}
        >
          Enrich
        </Button>
      </GridToolbarContainer >
    );
  }


  let columns = [
    {
      minWidth: 25,
      maxWidth: 50,
      field: 'id',
      headerName: 'Id',
      renderCell: (params) => {
        return <>
          <span style={fixes.find(i => i.product_id === params.id && i.Correct === 'No') ? { color: 'red', fontWeight: 'bold' } : {}}>{params.row.id}</span>
          {fixes.find(i => i.product_id === params.id && i.Correct === 'No') && <><Tooltip title={fixes.find(i => i.product_id === params.id && i.Correct === 'No').Why}><IconButton><InfoIcon style={{ verticalAlign: 'middle', color: '#ad0b0b' }} /></IconButton></Tooltip></>} </>
      }
    },
    {
      minWidth: 175,
      field: 'images_arr',
      headerName: 'Image',
      renderCell: (params) => {
        return <ImageStack images={!!params.row.images_arr ? params.row.images_arr.map(i => i.stored_name) : []} width={params.colDef.computedWidth} />
      }
    },
    ...catalogAttributes.map(attribute => {
      return {
        minWidth: 150,
        field: attribute.name,
        headerName: capitalize(attribute.name)
      }
    }),
    {
      field: 'created_at',
      headerName: 'Created At',
      sortable: true,
      minWidth: 150,
      type: 'dateTime',
      valueFormatter: (value) => new Date(value),
      renderCell: (params) => params.row.created_at ? moment().format('YYYY-MM-DD HH:mm') : 'XX',
    },
    {
      field: 'updated_at',
      headerName: 'Updated At',
      sortable: true,
      minWidth: 150,
      type: 'dateTime',
      valueFormatter: (value) => new Date(value),
      renderCell: (params) => params.row.updated_at ? moment().format('YYYY-MM-DD HH:mm') : 'XX',
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      sortable: false,
      valueFormatter: (_) => '...',
      renderCell: (params) => !!params.row ? <MoreActionsTable product={params.row} /> : '',
    },
  ]


  useEffect(() => {
    fetchAllImagesFromProject();
    fetchAllProductsFromProject();

    return () => {
      //Reset all drawers to closed state
      setProducts([]);
      setPreviewProducts([]);
      closedAllDrawers();
    };
  }, [])// eslint-disable-line


  return (
    <div style={{ width: '100%', minWidth: '450px' }}>
      <DataGrid
        autoHeight
        loading={isLoading}
        getRowId={(row, index) => !!row?.id ? row.id : index}
        slots={{ toolbar: CustomToolbar, loadingOverlay: LinearProgress, }}
        rows={products}
        columns={columns.map(column => ({
          ...column,
          flex: 1,
          minWidth: 100,
        }))}
        getRowClassName={(params) => fixes.find(i => i.product_id === params.id && i.Correct === 'No') ? `incorrect-row` : ''}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
          columns: {
            columnVisibilityModel: {
              created_at: false,
              attributes: false,
              comments: false
            },
          },

        }}
        onRowSelectionModelChange={(row) => {
          setProductSelected(products.find(i => i?.id === row[0]))
        }}
        pageSizeOptions={[5, 10, 15]}
        checkboxSelection={false}
        hideFooterSelectedRowCount={true}
        disableMultipleRowSelection={true}
        cellSelection={false}

      />
    </div>
  )
}
