import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useEffect } from 'react';
import { drawerFormWidth } from '../containers/TopDrawer';
import UploadContaner from './UploadContainer';

export default function AttributesCatalogUpload() {
	const loadingFormData = useStoreState(state => state.loadingFormData);
	const setLoadingFormData = useStoreActions(state => state.setLoadingFormData);
	const showUploadAttribute = useStoreState(state => state.showUploadAttribute);

	useEffect(() => {
		if (loadingFormData) {
			setLoadingFormData(false);
		}
		// eslint-disable-next-line
	}, [loadingFormData, setLoadingFormData])

	return (
		<Box sx={{ display: 'flex' }}>
			<Drawer
				sx={{
					width: drawerFormWidth,
					flexShrink: 0,
					'& .MuiDrawer-paper': {
						width: drawerFormWidth,
						top: "64px", // Subtract width of header
						// backgroundColor: 'beige'
					},
				}}
				variant="persistent"
				anchor="right"
				open={showUploadAttribute}
			>
				{!loadingFormData && <UploadContaner />}
			</Drawer>
		</Box>
	);
}
