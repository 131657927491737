
import React, { useState } from 'react'
import { useStoreActions } from 'easy-peasy';
import { Box, Button, IconButton, ListItemIcon, Menu, MenuItem, Modal } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import ContentCopyTwoToneIcon from '@mui/icons-material/ContentCopyTwoTone';
const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #ddddd',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    borderRadius: '5px'
};
export default function MoreActionsTable({ product }) {

    const [openModal, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const setLoadingFormData = useStoreActions(state => state.setLoadingFormData);
    const deleteProduct = useStoreActions(state => state.deleteProduct);
    const duplicateProduct = useStoreActions(state => state.duplicateProduct);
    const setProductSelected = useStoreActions(state => state.setProductSelected);
    const setShowFormProduct = useStoreActions(state => state.setShowFormProduct);

    const handleCloseModal = () => {
        setOpen(false);
    };
    const handleClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <IconButton
                onClick={handleClick}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                aria-label="actions"
                style={{ padding: 0 }}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                        marginLeft: '7px'
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem style={{ paddingLeft: '10px' }} onClick={() => { duplicateProduct(product?.id) }}>
                    <ListItemIcon>
                        <ContentCopyTwoToneIcon fontSize="small" />
                    </ListItemIcon>
                    Duplicate
                </MenuItem>
                <MenuItem style={{ paddingLeft: '10px' }} onClick={() => { setProductSelected(product); setLoadingFormData(true); setShowFormProduct(true); }}>
                    <ListItemIcon>
                        <EditTwoToneIcon fontSize="small" />
                    </ListItemIcon>
                    Edit
                </MenuItem>
                <MenuItem style={{ paddingLeft: '10px' }} onClick={() => { setOpen(true); }}>
                    <ListItemIcon>
                        <HighlightOffIcon fontSize="small" />
                    </ListItemIcon>
                    Delete
                </MenuItem>
            </Menu>
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{ ...styleModal, width: 600 }}>
                    <h2 id="child-modal-title">Are you sure you want to delete the product "{product.id}"?</h2>
                    <p id="child-modal-description">
                        This action can not be reversed
                    </p>
                    <Button onClick={handleCloseModal}>Cancel</Button>
                    <Button variant={"contained"} onClick={() => { deleteProduct(product.id); setOpen(false) }} style={{ float: 'right' }}>Delete</Button>
                </Box>
            </Modal>
        </>
    )
}
