import { Controller, useForm } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import { useMemo } from "react";
import { Box, Checkbox, Container, FormControl, ImageList, ImageListItem, ImageListItemBar, InputLabel, MenuItem, Select, TextField, Tooltip, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from '@mui/icons-material/Save';
import { useStoreActions, useStoreState } from "easy-peasy";
import { Close } from "@mui/icons-material";
import FormHelperTextCustom from "../common/FormHelperTextCustom";
import constants from "../../helpers/constants";
import SaveAsIcon from '@mui/icons-material/SaveAs';
import ContentCopyTwoToneIcon from '@mui/icons-material/ContentCopyTwoTone';
import IconButton from '@mui/material/IconButton';


function srcset(image, width, height, rows = 1, cols = 1) {
  return {
    src: `${constants.baseurl}/images/${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format`,
    srcSet: `${constants.baseurl}/images/${image}?w=${width * cols}&h=${height * rows
      }&fit=crop&auto=format&dpr=2 2x`,
  };
}
function ImagesCatalogList({ imagesCatalog, getValues, setValue }) {

  function handleSelect(stored_name, isChecked) {
    if (isChecked) {
      setValue('images', [...getValues('images'), stored_name]);
    } else {
      setValue('images', getValues('images').filter((item) => item !== stored_name));
    }

    return isChecked;
  }

  return (
    <ImageList
      className="new-product-card "
      rowHeight={150}
      gap={2}
    >

      {imagesCatalog.map((item, index) => {
        return (

          <ImageListItem key={index} sx={{
            border: '1px solid #e0e0e0',
          }}>
            <img
              {...srcset(item.stored_name)}
              alt={item.original_name}
              loading="lazy"
            />
            <ImageListItemBar
              sx={{
                color: 'black',
                // background:
                //   'linear-gradient(to bottom, rgba(240, 240, 240,0.7) 0%, ' +
                //   'rgba(240, 240, 240,0.3) 70%, rgba(240, 240, 240,0) 100%)',
              }}
              title={item.original_name}
              position="bottom"
              actionIcon={
                <Checkbox
                  defaultChecked={getValues('images').includes(item.stored_name)}
                  // checked={getValues('images').includes(item.stored_name)}
                  key={`IMG-${item.stored_name}`}
                  onChange={(_, value) => handleSelect(item.stored_name, value)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              actionPosition="left"
            />
          </ImageListItem>
        );
      })}
    </ImageList>
  );
}

function CatalogForm() {

  const catalogSelected = useStoreState(state => state.catalogSelected);
  const productSelected = useStoreState(state => state.productSelected);
  const createProductByCatalog = useStoreActions(state => state.createProductByCatalog);
  const updateProductByCatalog = useStoreActions(state => state.updateProductByCatalog);
  const setShowFormProduct = useStoreActions(state => state.setShowFormProduct);
  const loading = useStoreState(state => state.loading);
  const imagesCatalog = useStoreState(state => state.images);
  const duplicateProduct = useStoreActions(state => state.duplicateProduct);



  const { control, handleSubmit, formState: { errors }, getValues, setValue } = useForm({
    defaultValues: {
      ...useMemo(
        () =>
        ({
          ...catalogSelected.attributes.reduce((acc, attribute) => {
            acc[attribute.name] = productSelected?.[attribute.name] ?? '';
            return acc;
          }, {}),
          'images': productSelected?.images_arr?.map(image => image.stored_name) ?? []
        }),
        [catalogSelected.attributes, productSelected]
      ),
    },
    mode: "onChange",
  });

  const onSubmitOnHandler = (formData) => {
    const images_arr = formData.images;
    delete formData.images;

    if (!!productSelected) {
      updateProductByCatalog({ productId: productSelected?.id, attributes: formData, images_arr });
    } else {
      createProductByCatalog({ is_manual: true, attributes: formData, images_arr });
    }
  };


  const ComponentForm = ({ name, type, options, index }) => {
    switch (type) {
      case 'Text Field':
        return (<Controller
          key={`CF-${name}-${index}`}
          name={name}
          rules={{ required: true }}
          control={control}
          render={({ field: { ref, ...field } }) => (
            <FormControl {...field} error={!!errors?.[name]} style={{ width: '100%', marginTop: `${index === 0 ? 5 : 0}% ` }}>
              <TextField
                {...field}
                error={!!errors?.[name]}
                label={name}
                disabled={loading}
                style={{ width: '100%', marginTop: '5%' }}
                variant={'outlined'}
              />
              <FormHelperTextCustom key={`CF-errors.name`} showHelper={!!errors?.[name]} />
            </FormControl>
          )}
        />)
      case 'Dropdown':
        return (<Controller
          key={`CF-${name}- ${index} `}
          name={name}
          rules={{ required: true }}
          control={control}
          render={({ field: { ref, ...field } }) => (
            <FormControl {...field} error={!!errors?.[name]} style={{ width: '100%', marginTop: '5%' }}>
              <InputLabel error={!!errors?.[name]} id={`select-label-${name}-${index}`}>{name}</InputLabel>
              <Select
                {...field}
                disabled={loading}
                labelId={`select-label-${name}-${index}`}
                id={`select-label-${name}-${index}`}
                label={name}
              >
                {options.map((option, idx) => {
                  return <MenuItem key={`CFOPTS-${name}-${index}-${idx}`} value={option}>{option}</MenuItem>
                })}
              </Select>
              <FormHelperTextCustom key={`CF-errors.name`} showHelper={!!errors?.[name]} />
            </FormControl>
          )}
        />)
      default: <></>
    }
  }

  return (
    <Container maxWidth="sm">
      <form onSubmit={handleSubmit(onSubmitOnHandler)}>
        <Box display="flex" justifyContent="space-between" alignItems="center" position="relative" marginTop="5%">
          <Typography variant="h6" style={{ color: 'black', }}>{!!productSelected ? `Edit Product #${productSelected.id} ` : "Create Product"}</Typography>
          {!!productSelected && <Tooltip title="Duplicate">
            <IconButton
              size="medium"
              disabled={loading}
              loading={loading}
              variant={"outlined"}
              onClick={() => { setShowFormProduct(false); duplicateProduct(productSelected.id); }}
            >
              <ContentCopyTwoToneIcon />
            </IconButton>
          </Tooltip>
          }
        </Box>

        {catalogSelected.attributes?.map((attribute, index) => {
          return ComponentForm({ ...attribute, index })
        })}
        <hr style={{ marginTop: '5%' }} />
        <Typography variant="span" style={{ color: `${!!errors?.images ? '#d32f2f' : 'black'}`, marginTop: '5%' }}>{/* !!catalogSelected ? `Edit Catalog #${ catalogSelected.id } ` :  */"List of images:"}</Typography>

        <ImagesCatalogList imagesCatalog={imagesCatalog} getValues={getValues} setValue={setValue} />
        <Controller
          key={`IMG-CustomImageList`}
          name={'images'}
          rules={{ required: { value: true, message: "Select at least one image" } }}
          control={control}
          render={({ field: { ref, ...field } }) => (
            <FormControl {...field} error={!!errors?.images} style={{ width: '100%', marginTop: `0%` }}>
              <FormHelperTextCustom key={`CF-errors.name`} showHelper={!!errors?.images} />
            </FormControl>
          )}
        />


        <LoadingButton loading={loading} startIcon={<Close />} style={{ minWidth: '45%', marginTop: '20px', }} size="small" variant={"outlined"} onClick={() => { setShowFormProduct(false) }} >Close</LoadingButton>
        <LoadingButton loading={loading} startIcon={productSelected ? <SaveAsIcon /> : <SaveIcon />} style={{ minWidth: '45%', marginTop: '20px', float: 'right' }} size="small" variant={"contained"}
          type="submit" >{!productSelected ? 'Create' : 'Save'}</LoadingButton>
      </form>

      <DevTool control={control} />
    </Container >
  );
};

export default CatalogForm;