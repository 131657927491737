import { Avatar } from '@mui/material';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import utils from '../../helpers/utils'
import CloudCircleOutlinedIcon from '@mui/icons-material/CloudCircleOutlined';

export default function PreviewTable({ images }) {

    const apiRef = useGridApiRef();
    let columns = [
        {
            field: 'image',
            headerName: 'Image',
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return <Avatar src={utils.createObjectURL(params.row)} variant="rounded" className='image-custom-styles' style={{ width: '100%', height: '100%', border: '0px', marginLeft: '-10px', borderRadius: '0px', borderRight: '1px solid rgb(224, 224, 224)' }} />
            }
        },
        {
            field: 'name',
            headerName: 'Name',
            width: 100
        },
        {
            field: 'type',
            headerName: 'Type',
            width: 100
        },
        {
            field: 'size',
            headerName: 'Size',
            width: 70,
            renderCell: (params) => <>{utils.bytesToMegaBytes(parseInt(params?.row.size))} MB</>,
        },
    ]
    return (
        <div className='preview-data-grid' style={{ width: '100%', marginTop: '5%', marginBottom: '15%' }}>
            <DataGrid
                disableColumnSorting
                disableColumnFilter
                apiRef={apiRef}
                getRowId={(row) => row.name}
                rows={images}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                    }
                }}
                density="compact"
                pageSizeOptions={[5, 10, 15]}
                checkboxSelection={false}
                hideFooterSelectedRowCount={true}
                disableMultipleRowSelection={true}
            />
             <span style={{ position: 'absolute', right: '15px', display: 'flex', marginTop:'5%', marginRight:'10px' }}><CloudCircleOutlinedIcon style={{ marginRight: '15px' }} /> Preview Added Size: {utils.bytesToMegaBytes(images.reduce((a, b) => a + parseInt(b.size), 0))} MB</span>
        </div>
    )
}
