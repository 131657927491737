import React, { useEffect, useState } from 'react';
import Carousel from 'react-material-ui-carousel'
import { Paper } from '@mui/material'
import constants from '../../helpers/constants';
import { useStoreState } from 'easy-peasy';
import axios from 'axios';


function ImageStack({ images, width, images_id = null }) {
  return (
    <Carousel
      width={width}
      indicators={false}
      navButtonsAlwaysInvisible={true}
    >
      {
        images.map((image, index) => <Item identifier={(!!images_id && !!images_id[index]) ? images_id[index] : undefined} key={index} index={index} image={image} maxHeight={width / 2} />)
      }
    </Carousel>
  )
}
function Item({ image, index, identifier }) {
  const [backgroundImage, setBackgroundImage] = useState('');
  const token = useStoreState(state => state.auth.token);
  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await axios.get(`${constants.baseurl}/images/${image}`, {
          headers: {
            Authorization: `JWT ${token}`
          },
          responseType: 'blob'
        });

        const imageUrl = URL.createObjectURL(response.data);
        setBackgroundImage(imageUrl);
      } catch (error) {
        console.error('Error fetching the image', error);
      }
    };

    fetchImage();
  // eslint-disable-next-line
  }, []);
  return (
    <Paper style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', height: '100%', backgroundPosition: 'center' }}>
      <h2 style={{ margin: '0px' }}>#{!!identifier ? identifier : index + 1}</h2>
    </Paper>
  )
}


export default ImageStack;