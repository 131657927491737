import { useEffect } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import { Avatar, Button, Chip } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import IconBasedOnString from '../common/IconBasedOnString';
import CssBaseline from '@mui/material/CssBaseline';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { ReactComponent as Logo } from '../../assets/icon.svg';
import Inventory2TwoToneIcon from '@mui/icons-material/Inventory2TwoTone';


const drawerWidth = 240;
export const drawerFormWidth = 450;

export default function TopDrawer(props) {
  const auth = useStoreState(state => state.auth);
  const options = useStoreState(state => state.options);
  const setAuth = useStoreActions(state => state.setAuth);
  const setTabSelected = useStoreActions(state => state.setTabSelected);
  const catalogSelected = useStoreState(state => state.catalogSelected);
  const showFormCatalog = useStoreState(state => state.showFormCatalog);
  const showFormAttribute = useStoreState(state => state.showFormAttribute);
  const tabSelected = useStoreState(state => state.navigation.tabSelected);
  const fetchAllCatalogs = useStoreActions(state => state.fetchAllCatalogs);
  const setCatalogSelected = useStoreActions(state => state.setCatalogSelected);
  const showUploadImages = useStoreState(state => state.showUploadImages);
  const showUploadAttribute = useStoreState(state => state.showUploadAttribute);
  const showUploadProducts = useStoreState(state => state.showUploadProducts);
  const showFormProduct = useStoreState(state => state.showFormProduct);
  const showGenerateIA = useStoreState(state => state.showGenerateIA);
  const showCorrectFix = useStoreState(state => state.showCorrectFix);
  const showEnrich = useStoreState(state => state.showEnrich);
  const showRelateToProducts = useStoreState(state => state.showRelateToProducts);
  const fetchApplicationOptions = useStoreActions(state => state.fetchApplicationOptions);


  useEffect(() => {
    if (options?.version === '0.0.0') fetchApplicationOptions();
  }, [fetchApplicationOptions, options?.version]);

  let menusAvailable = ['Catalog List', 'Catalog Attributes', 'Product Listings', 'Catalog Images'];

  if (!catalogSelected) menusAvailable = menusAvailable.filter(i => i === 'Catalog List')

  if (catalogSelected?.attributes?.length === 0) menusAvailable = menusAvailable.filter(i => i !== 'Product Listings')


  if (!auth) menusAvailable = ['Authentication'];

  return (auth ? (<Box sx={{ display: 'flex' }}>
    <CssBaseline />
    <AppBar
      position="fixed"
      className='app-bar-custom-styles'
      sx={{ ml: `${drawerWidth}px`, zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Toolbar>
        <Logo style={{ minWidth: '100px', height: '50px', display: { md: 'flex' }, cursor: 'pointer' }} onClick={() => {
          setTabSelected('Catalog List');
        }} />
        <div className='powered-by-custom-styles' style={{ display: { xs: 'none' } }}>
          <div>betaV{options.version}</div>
        </div>
        {!!catalogSelected?.name && <Chip style={{ backgroundColor: '#fff', marginLeft: '35px' }} icon={<Inventory2TwoToneIcon />} label={catalogSelected?.name} size="small" onDelete={() => {
          setTabSelected('Catalog List');
          setCatalogSelected(null);
        }} />}
        {!!auth?.decodedToken && <div className='app-bar-buttons-custom-styles'>
          <Button size="small" variant="inherit" style={{ borderRadius: '50px' }}><Avatar sx={{ width: 32, height: 32, mr: 1 }}>N</Avatar> {auth?.decodedToken?.username}</Button>
          <Button size="small" variant="inherit" onClick={() => { setTabSelected('Authentication'); setAuth(null); }}>Logout</Button>
        </div>}
      </Toolbar>
    </AppBar>
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Toolbar style={{ padding: '0px' }}>

      </Toolbar>
      <Divider />
      <List>
        {menusAvailable.map((menu, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton onClick={() => {
              if (menu === 'Catalog List') fetchAllCatalogs();
              setTabSelected(menu)
            }} selected={tabSelected === menu}>
              <ListItemIcon>
                <IconBasedOnString designation={menu} />
              </ListItemIcon>
              <ListItemText primary={menu} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Drawer>
    <Box
      component="main"
      sx={{ width: `calc(100% - ${drawerWidth + ((showFormProduct || showFormCatalog || showUploadAttribute || showFormAttribute || showUploadImages || showUploadProducts || showRelateToProducts || showGenerateIA || showCorrectFix || showEnrich) ? drawerFormWidth : 0)}px)`, bgcolor: 'background.default', p: 3 }}
    >
      {props.children}
    </Box>
  </Box>) : <>{props.children}</>

  );
}