//Libraries
import ACTIONS from './actions';
import INITIAL_STATE from './initial_state';
import THUNKS from './thunks';
const index = {
    //Initial State
    ...INITIAL_STATE,
    //Actions
    ...ACTIONS,
    //Thunks
    ...THUNKS
};
export default index;
