
import React, { useEffect } from 'react';
import AttributesCatalogTable from '../attributesList/AttributesCatalogTable';
import { useStoreActions, useStoreState } from 'easy-peasy';
import AttributesCatalogUpload from '../attributesList/AttributesCatalogUpload';
import AttributesListDrawer from '../attributesList/AttributesListDrawer';


export default function AttributesCatalogContainer() {
    const fetchAllAttributes = useStoreActions(state => state.fetchAllAttributes);
    const catalogSelected = useStoreState(state => state.catalogSelected);
    const showFormAttribute = useStoreState(state => state.showFormAttribute);
    const showUploadAttribute = useStoreState(state => state.showUploadAttribute);


    useEffect(() => {
        fetchAllAttributes(catalogSelected.id)
    }, [])// eslint-disable-line

    return (
        <div className='attributes-catalog-container-custom-styles'>
            <AttributesCatalogTable />
            {showFormAttribute && <AttributesListDrawer />}
            {showUploadAttribute && <AttributesCatalogUpload />}
        </div>
    )
}
