import { Container, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import { LoadingButton } from "@mui/lab";
import LayersClearIcon from '@mui/icons-material/LayersClear';
import { useDropzone } from 'react-dropzone';
import utils from '../../helpers/utils'
import InfoIcon from '@mui/icons-material/Info';
import PreviewTable from './PreviewTable';
import { useStoreActions, useStoreState } from "easy-peasy";
import { Close } from "@mui/icons-material";
import SaveIcon from '@mui/icons-material/Save';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';

export default function UploadContainer() {

  const images = useStoreState(state => state.images);
  const uploadImages = useStoreActions(state => state.uploadImages);
  const replaceImages = useStoreActions(state => state.replaceImages);
  const setShowUploadImages = useStoreActions(state => state.setShowUploadImages);
  
  
  const [errorMessage, setErrorMessage] = useState(null);
  const [previewImages, setPreviewImages] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (acceptedFiles) => {
      const unallowedType = acceptedFiles.filter(i => !i.type.includes('image'));
      const overflowdImageSize = acceptedFiles.filter(i => utils.bytesToMegaBytes(i.size) > 4);
      //Error Handling
      if (unallowedType.length > 0) return setErrorMessage('Unexpected type of file uploaded');
      else if (overflowdImageSize.length > 0) return setErrorMessage('File uploaded must be below 4MB');
      else if (acceptedFiles.filter(i => images.find(j => j.original_name === i.name)).length !== 0) return setErrorMessage('Images cannot have duplicate name in the catalog');
      else setErrorMessage(null);
      setPreviewImages(acceptedFiles);
    },
  });

  
  const handleClickSave = async () => {
    const formData = utils.jsonToFormData({
      images: previewImages
    });
    uploadImages(formData);
  }
  const replace = async () => {
    const formData = utils.jsonToFormData({
      images: previewImages
    });
    replaceImages(formData);
  }
  return (
    <Container maxWidth="sm">
      <Typography variant="h6" style={{ color: 'black', marginTop: '5%' }}>{previewImages.length > 0 ? 'Preview' : 'Upload'} Images</Typography>
      {previewImages.length > 0 && <Tooltip title="Clear catalog images previewed"><LoadingButton loading={false} style={{ marginTop: '-30px', position: 'absolute', right: '25px' }} size="small" variant={"outlined"} onClick={() => { setPreviewImages([]); }} ><LayersClearIcon /></LoadingButton></Tooltip>}
      {previewImages.length === 0 &&
        <>
          <div style={{ marginTop: '5%' }}>
            <div className='drag-and-drop-custom-styles' {...getRootProps()}>
              <input {...getInputProps()} />
              <p>Drag and drop files here or click to browse.</p>
            </div>
            {errorMessage && <div style={{ display: 'flex' }}><InfoIcon />{errorMessage}</div>}
          </div>
        </>}
        {previewImages.length > 0 && <PreviewTable images={previewImages} />}
        <LoadingButton loading={false} startIcon={<Close />} style={{ minWidth: '45%', marginTop: '20px', }} size="small" variant={"outlined"} onClick={() => { setPreviewImages([]); setShowUploadImages(false) }} >Close</LoadingButton>
        {previewImages.length > 0 && <LoadingButton loading={false} startIcon={<SaveIcon />} style={{ minWidth: '25%', marginTop: '20px', float: 'right', marginLeft: '10px' }} size="small" variant={"contained"} onClick={() => { handleClickSave();setShowUploadImages(false) }} >Save</LoadingButton>}
        {previewImages.length > 0 && <LoadingButton loading={false} startIcon={<PublishedWithChangesIcon />} style={{ minWidth: '25%', marginTop: '20px', float: 'right' }} size="small" variant={"contained"} onClick={() => { replace(); setShowUploadImages(false) }} >Replace</LoadingButton>}
    </Container>
  )
}
