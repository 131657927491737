import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CatalogForm from './CatalogForm';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useEffect } from 'react';
import { drawerFormWidth } from '../containers/TopDrawer';

export default function CalalogListDrawer() {

	const loadingFormData = useStoreState(state => state.loadingFormData);
	const setLoadingFormData = useStoreActions(state => state.setLoadingFormData);
	const showFormCatalog = useStoreState(state => state.showFormCatalog);
	const catalogSelected = useStoreState(state => state.catalogSelected);

	useEffect(() => {
		if (loadingFormData) {
			setLoadingFormData(false);
		}
		return () => {
			setLoadingFormData(false)
		};
	}, [loadingFormData, setLoadingFormData])

	return (
		<Box sx={{ display: 'flex' }}>
			<Drawer
				sx={{
					width: drawerFormWidth,
					flexShrink: 0,
					'& .MuiDrawer-paper': {
						width: drawerFormWidth,
						top: "64px", // Subtract width of header
						// backgroundColor: 'beige'
					},
				}}
				variant="persistent"
				anchor="right"
				open={showFormCatalog}
			>
				{!loadingFormData && <CatalogForm catalogSelected={catalogSelected} />}
			</Drawer>
		</Box>
	);
}
