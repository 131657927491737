import moment from 'moment';
import { useEffect, useMemo } from 'react';
import { Box, Button } from '@mui/material';
import utils from '../../helpers/utils'
import { useStoreActions, useStoreState } from 'easy-peasy';
import CategoryIcon from '@mui/icons-material/CategoryTwoTone';
import CheckCircleIcon from '@mui/icons-material/CheckCircleTwoTone';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CloudCircleOutlinedIcon from '@mui/icons-material/CloudCircleOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturingTwoTone';
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton } from '@mui/x-data-grid';
import MoreActionsAllCatalogTable from './MoreActionsAllCatalogTable';
import LinearProgress from '@mui/material/LinearProgress';
import PermMediaIcon from '@mui/icons-material/PermMediaTwoTone';


export default function CatalogsTable() {
    const catalogs = useStoreState(state => state.catalogs);
    const loading = useStoreState(state => state.loading);
    const setMyCatalog = useStoreActions(state => state.setMyCatalog);
    const deleteCatalog = useStoreActions(state => state.deleteCatalog);
    const createCatalog = useStoreActions(state => state.createCatalog);
    const catalogSelected = useStoreState(state => state.catalogSelected);
    const setSavedImages = useStoreActions(state => state.setSavedImages);
    const fetchAllCatalogs = useStoreActions(state => state.fetchAllCatalogs);
    const setShowFormCatalog = useStoreActions(state => state.setShowFormCatalog);
    const setShowSavedImages = useStoreActions(state => state.setShowSavedImages);
    const setCatalogSelected = useStoreActions(state => state.setCatalogSelected);
    const setLoadingFormData = useStoreActions(state => state.setLoadingFormData);
    const setShowSavedCatalog = useStoreActions(state => state.setShowSavedCatalog);
    const setCatalogAttributes = useStoreActions(state => state.setCatalogAttributes);

    useEffect(() => {
        if (catalogs.length === 0) fetchAllCatalogs()
        return () => setShowFormCatalog(false);
        // eslint-disable-next-line
    }, [])

    const selectRow = (catalog) => {
        if (catalogSelected?.id === catalog?.id || catalog === null) {
            // setCatalogSelected(null)
            setSavedImages([])
            setShowSavedImages(false)
            setCatalogAttributes([])
            setMyCatalog([]);
            setLoadingFormData(true);
        }
        else {
            setCatalogSelected(catalog)
            setSavedImages(!!catalog.images ? catalog.images.map(i => `images/${i}`) : [])
            setShowSavedImages(!!catalog.images ? true : false)
            setCatalogAttributes(!!catalog.attributes ? catalog.attributes : [])
            setMyCatalog(!!catalog.result ? catalog.result : [])

            if (!!catalog.attributes && catalog.attributes.length > 0) {
                setShowSavedCatalog(true);
            }
            else {
                setShowSavedCatalog(true);
            }
            setLoadingFormData(true);

        }
    }

    const CustomToolbar = () => {


        return (
            <GridToolbarContainer style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                    <GridToolbarColumnsButton />
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                    <Button startIcon={<AddCircleOutlineOutlinedIcon />} onClick={() => showForm(true)}>
                        Create New
                    </Button>
                </Box>
                <Box style={{ display: 'flex', alignItems: 'center', marginRight: '1%' }}>
                    <CloudCircleOutlinedIcon style={{ marginRight: '5px' }} />
                    All Catalogs Size: {utils.bytesToMegaBytes(catalogs.reduce((a, b) => a + parseInt(b.size), 0))} MB
                </Box>
            </GridToolbarContainer>
        );
    }

    const showForm = () => {
        setCatalogSelected(null);
        setShowFormCatalog(true);
        setLoadingFormData(true);
    }

    const removeRow = (id) => {
        deleteCatalog(id)
    }

    const editRow = () => {
        setShowFormCatalog(true)
    }

    const columns = useMemo(() => ([
        {
            field: 'id',
            maxWidth: 50,
            headerName: 'ID',
        },
        {
            field: 'name',
            headerName: 'Name',
            minWidth: 200,
        },
        {
            field: 'comments',
            headerName: 'Comments',
            minWidth: 175
        },
        {
            field: 'status',
            headerName: 'Status',
            maxWidth: 70,
            sortable: false,
            filterable: false,
            description: 'If the project is checked the catalog has valid product and attributes',
            valueFormatter: (params) => !params?.row.result || params?.row.result.length === 0 ? 'Incomplete' : 'Complete',
            renderCell: (params) => {
                return params?.row?.catalog_products_number === "0" ? <RadioButtonCheckedIcon style={{ color: 'orange', verticalAlign: 'middle' }} /> : <CheckCircleIcon className="check-circle-custom-styles" style={{ verticalAlign: 'middle' }} />;
            },
        },
        {
            field: 'owner',
            headerName: 'Owner',
            minWidth: 150,
        },
        {
            field: 'attributes',
            headerName: 'Attributes',
            sortable: false,
            filterable: false,
            valueFormatter: (value) => !!value?.length ? value.length : '0',
            renderCell: (params) => <div><CategoryIcon style={{ verticalAlign: 'middle' }} /> x {!!params?.row?.attributes?.length ? params?.row.attributes.length : '0'}</div>,
        },
        {
            field: 'catalog_products_number',
            headerName: 'Products',
            sortable: false,
            filterable: false,
            valueFormatter: (value) => !!value?.length ? value.length : '0',
            renderCell: (params) => <div><PrecisionManufacturingIcon style={{ verticalAlign: 'middle' }} /> x {!!params?.row?.catalog_products_number ? params.row.catalog_products_number : '0'}</div>,
        },
        {
            field: 'catalog_images_number',
            headerName: 'Images',
            sortable: false,
            filterable: false,
            valueFormatter: (value) => !!value?.length ? value.length : '0',
            renderCell: (params) => <div><PermMediaIcon style={{ verticalAlign: 'middle' }} /> x {!!params?.row?.catalog_images_number ? params.row.catalog_images_number : '0'}</div>,
        },
        {
            field: 'size',
            headerName: 'Size',
            renderCell: (params) => <>{params?.row.size ? utils.bytesToMegaBytes(parseInt(params?.row.size)) : '0'} MB</>,
        },
        {
            field: 'created_at',
            headerName: 'Created',
            sortable: true,
            minWidth: 150,
            type: 'dateTime',
            valueFormatter: (value) => new Date(value),
            renderCell: (params) => params.row.created_at ? moment().format('YYYY-MM-DD HH:mm') : 'XX',
        },
        {
            field: 'updated_at',
            headerName: 'Last Updated',
            sortable: true,
            minWidth: 150,
            type: 'dateTime',
            valueFormatter: (value) => new Date(value),
            renderCell: (params) => params.row.updated_at ? moment().format('YYYY-MM-DD HH:mm') : 'XX',
        },
        {
            field: 'actions',
            headerName: 'Actions',
            type: 'actions',
            sortable: false,
            valueFormatter: (_) => '...',
            renderCell: (params) => !!params.row ? <MoreActionsAllCatalogTable selectRow={selectRow} catalogSelected={catalogSelected} catalog={params.row} editRow={editRow} handleDeleteCatalog={removeRow} createCatalog={createCatalog} /> : '',
        },
    ]), [])// eslint-disable-line


    return (
        <div className="table-custom-styling" style={{ width: '100%', minWidth: '450px' }}>
            <DataGrid
                autoHeight
                slots={{
                    toolbar: CustomToolbar,
                    loadingOverlay: LinearProgress,
                }}
                loading={loading}
                rows={catalogs}
                columns={columns.map(column => ({
                    ...column,
                }))}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                    },
                    columns: {
                        columnVisibilityModel: {
                            created_at: false,
                            attributes: false,
                            comments: false
                        },
                    },
                }}
                pageSizeOptions={[5, 10, 15]}
                // checkboxSelection
                onRowSelectionModelChange={(row) => {
                    if (row.length === 0) {
                        selectRow(null)
                    }
                    if (!!catalogs.find(i => i.id === row?.[0])) selectRow(catalogs.find(i => i.id === row?.[0]));
                    return row;
                }}
                onRowDoubleClick={() => {
                    editRow()
                }}
                cellSelection={false}
                rowSelectionModel={!!catalogSelected?.id ? [catalogSelected?.id] : []}
                hideFooterSelectedRowCount={true}
                disableMultipleRowSelection={true}
            />
        </div>
    )
}