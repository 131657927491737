import { Container, ImageListItem, ImageListItemBar, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { LoadingButton } from "@mui/lab";
import { useStoreActions, useStoreState } from "easy-peasy";
import { Close } from "@mui/icons-material";
import SaveIcon from '@mui/icons-material/Save';
import constants from '../../helpers/constants';
import CustomAutocomplete from '../common/CustomAutocomplete';


function srcset(image, width, height, rows = 1, cols = 1) {
    return {
      src: `${constants.baseurl}/images/${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format`,
      srcSet: `${constants.baseurl}/images/${image}?w=${width * cols}&h=${height * rows
        }&fit=crop&auto=format&dpr=2 2x`,
    };
  }
export default function RelateToProductForm() {
    const products = useStoreState(state => state.products);
    const imageSelected = useStoreState(state => state.imageSelected);
    const setShowRelateToProducts = useStoreActions(state => state.setShowRelateToProducts);
    const fetchAllProductsFromProject = useStoreActions(state => state.fetchAllProductsFromProject);
    const updateCatalogProductImages = useStoreActions(state => state.updateCatalogProductImages);
    const [productsSelected, setProductsSelected] = useState(products.filter(p => !!p?.images_arr && p?.images_arr.filter(i => i.id === parseInt(imageSelected?.id)).length > 0));

    useEffect(() => {
        fetchAllProductsFromProject()
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(!!products) setProductsSelected(products.filter(p => !!p?.images_arr && p?.images_arr.filter(i => i.id === parseInt(imageSelected?.id)).length > 0))
    // eslint-disable-next-line
    }, [imageSelected])

    return (
        <Container maxWidth="sm">
            <Typography variant="h6" style={{ color: 'black', marginTop: '5%' }}>Relate #{imageSelected?.id} to Product</Typography>
            <div style={{ marginTop: '20px', marginBottom:'20px', width:'100%' }}>
                <CustomAutocomplete products={products} productsSelected={productsSelected} setProductsSelected={setProductsSelected}/>
            </div>
            <ImageListItem sx={{
                border: '1px solid #e0e0e0',
            }}>
                <img
                    {...srcset(imageSelected.stored_name)}
                    alt={imageSelected.original_name}
                    loading="lazy"
                />
                <ImageListItemBar
                    sx={{
                        color: 'black',
                        // background:
                        //   'linear-gradient(to bottom, rgba(240, 240, 240,0.7) 0%, ' +
                        //   'rgba(240, 240, 240,0.3) 70%, rgba(240, 240, 240,0) 100%)',
                    }}
                    title={imageSelected.original_name}
                    position="bottom"
                    actionPosition="left"
                />
            </ImageListItem>
            <LoadingButton loading={false} startIcon={<SaveIcon />} style={{ minWidth: '45%', marginTop: '20px', float: 'right', marginLeft: '10px' }} size="small" variant={"contained"} onClick={() => { setShowRelateToProducts(false);  updateCatalogProductImages(productsSelected.map(p => ({product_id:p.id, image_id:imageSelected.id})))}} >Save</LoadingButton>
            <LoadingButton loading={false} startIcon={<Close />} style={{ minWidth: '45%', marginTop: '20px', }} size="small" variant={"outlined"} onClick={() => { setShowRelateToProducts(false) }} >Close</LoadingButton>
        </Container>
    )
}
