import React from 'react'
import { DataGrid } from '@mui/x-data-grid';
import { useStoreState } from 'easy-peasy';
import ImageStack from './ImageStack';
import { v4 as uuidv4 } from 'uuid';

const capitalize = s => (s && s[0].toUpperCase() + s.slice(1)) || ""

export default function PreviewTable({ products }) {
  const catalogAttributes = useStoreState(state => state.catalogAttributes);
  let columns = catalogAttributes.map(attribute => {
    return {
      minWidth: 175,
      field: attribute.name,
      headerName: capitalize(attribute.name)
    }
  });
  columns.unshift({
    minWidth: 175,
    field: 'Product Image File Name',
    headerName: 'Image',
    renderCell: (params) => {
      let array = [];
      if (!!params.row['Product Image File Name']) array = params.row['Product Image File Name'];
      else if (!!params.row?.images_arr) array = params.row?.images_arr?.map(i => i.stored_name);
      return <ImageStack images={array} width={params.colDef.computedWidth} />
    }
  });
  return (
    <div className='preview-data-grid' style={{ width: '100%', marginTop: '5%', minHeight: '200px' }}>
      <DataGrid
        disableColumnSorting
        disableColumnFilter
        getRowId={(row) => {
          return !!row?.id ? row?.id : uuidv4()
        }}
        rows={products}
        columns={columns.map(column => ({
          ...column,
          flex: 1,
          minWidth: 100,
        }))}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          }
        }}
        density='comfortable'
        pageSizeOptions={[5, 10, 15]}
        checkboxSelection={false}
        hideFooterSelectedRowCount={true}
        disableMultipleRowSelection={true}
      />
    </div>
  )
}