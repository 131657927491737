import { Chip } from '@mui/material';
import React from 'react';
import FilePresentIcon from '@mui/icons-material/FilePresent';
function DownloadLink({ fileName }) {

  const handleClick = () => {
    const link = document.createElement('a');
    link.href = `${process.env.PUBLIC_URL}/${fileName}`;
    link.setAttribute('download', fileName);
    link.click();
  };

  return (
    <div style={{textAlign:'left', marginBottom:'10px', display:'inline-block'}}>
      <Chip icon={<FilePresentIcon color="#61dafb" />} className="download-chip-custom-styles" label={fileName} clickable onClick={handleClick}/>
    </div>
    
  );
}

export default DownloadLink;