import ExcelJS from 'exceljs';
const getRandomSmoothColor = () => {
  // Define base color and variation ranges for vibrant colors
  const baseColor = 192; // Start closer to the middle for vibrancy
  const variation = 60;

  // Generate random variations for red, green, and blue components
  const red = Math.floor(Math.random() * variation) + baseColor - variation;
  const green = Math.floor(Math.random() * variation) + baseColor - variation;
  const blue = Math.floor(Math.random() * variation) + baseColor - variation;

  // Ensure values stay within 0-255 range
  const clampedRed = Math.max(0, Math.min(255, red));
  const clampedGreen = Math.max(0, Math.min(255, green));
  const clampedBlue = Math.max(0, Math.min(255, blue));

  // Convert component values to two-digit hexadecimal string
  const hexColor = `${clampedRed.toString(16).padStart(2, '0')}${clampedGreen.toString(16).padStart(2, '0')}${clampedBlue.toString(16).padStart(2, '0')}`;

  // Set the background color and font color for the cell
  return hexColor;
}


const utils = {
  bytesToMegaBytes: (bytes) => {
    // Check for invalid input (negative or non-numeric)
    if (typeof bytes !== 'number' || bytes < 0) {
      return 'Invalid input: Please provide a positive number of bytes.';
    }

    // Conversion factor (1 MB is equal to 1048576 bytes)
    const conversionFactor = 1048576;

    // Convert bytes to megabytes and round to two decimal places
    const megaBytes = (bytes / conversionFactor).toFixed(2);

    return parseFloat(megaBytes);
  },
  createObjectURL(file) {
    if (!window.URL || !file) return null;
    return window.URL.createObjectURL(file);
  },
  jsonToFormData(object) {
    const bodyFormData = new FormData();
    for (const param in object) {
      if (param === 'images') {
        !!object[param] &&
          object[param].forEach((file) => {
            bodyFormData.append('images', file);
          });
      } else {
        bodyFormData.append(param, object[param]);
      }
    }
    return bodyFormData;
  },
  getRandomSmoothColor,
  isDefined(field) {
    return field !== undefined && field !== null;
  },
  isFieldDefined(field) {
    return utils.isDefined(field) && field !== ''
  },
  isNumberDefined(number) {
    return !isNaN(number) && utils.isFieldDefined(number);
  },
  downloadTable: async (attributes, products, options = {}) => {
    try {
      //Loading download options
      const {fileName = null, hideProducts = false, hideInstructions = false, hideAttributes = false, showJustImage=false} = options;


      if (!attributes || !Array.isArray(attributes) || attributes.length === 0) return 'No data to download';
      
      console.log("Downloading table...");
      

      const response = await fetch('/template_empty_v2.xlsx');
      const blobT = await response.blob();

      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(blobT);

      //Hide Instructions Worksheet
      if(hideInstructions){
        let worksheetAttributesInstructions = workbook.getWorksheet('Product Attributes (Example)');
        worksheetAttributesInstructions.state = 'hidden';
        let worksheetProductsInstructions = workbook.getWorksheet('Product Listing (Example)');
        worksheetProductsInstructions.state = 'hidden'; 
      }
      //Hide Attributes Worksheet
      if(hideAttributes){
        let worksheetAttributes = workbook.getWorksheet('Product Attributes');
        worksheetAttributes.state = 'hidden';
        let worksheetAttributesInstructions = workbook.getWorksheet('Product Attributes (Example)');
        worksheetAttributesInstructions.state = 'hidden';
      }
      


      
      //Product Attributes Worksheet
      const worksheet = workbook.getWorksheet('Product Attributes');
      attributes.forEach((catalog, index) => {
        ["name", "unique_code","no_ia", "has_prompt", "prompt", "type", "options"].forEach((key, indexKey) => {
          //Boolean Values
          if(["unique_code", "no_ia", "has_prompt"].includes(key)){
            worksheet.getCell(1 + indexKey, 2 + index).value = !!catalog[key] ? 'TRUE' : 'FALSE';
          }
          //Custom Rule from Prompt Attribute
          else if(key === 'prompt'){
            worksheet.getCell(1 + indexKey, 2 + index).value = catalog.has_prompt ? catalog.prompt : '';
          }
          //String Non-Array Values
          else if (key !== 'options') {
            worksheet.getCell(1 + indexKey, 2 + index).value = catalog[key];
          }
          //Array Values
          else {
            catalog[key].forEach((option, indexOption) => {
              worksheet.getCell(1 + indexKey + indexOption, 2 + index).value = option;
            })
          }
        })
      })
      //----------------------------------------------------------------------
      //Hide if no-products are available
      let worksheetProductsListing = workbook.getWorksheet('Product Listing');
      if(hideProducts) worksheetProductsListing.state = 'hidden';
      //Product Classification Worksheet
      if (!!products && Array.isArray(products)) {
        let tmpMyCatalog = products

        //HEADERS - Add headers to first rows
        worksheetProductsListing.getCell(1, 1).value = "Product Image File Name";
        worksheetProductsListing.getCell(1, 1).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: getRandomSmoothColor() },
        };
        if(showJustImage){
          console.log("Show Just image columns")
        }
        // Add attributes based on product
        else if (!!tmpMyCatalog?.[0]) {
          Object.keys(tmpMyCatalog?.[0]).filter(key => !['images_arr', 'created_at', 'updated_at'].includes(key)).forEach((key, index) => {
            worksheetProductsListing.getCell(1, 2 + index).value = key;
            worksheetProductsListing.getCell(1, 2 + index).fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: getRandomSmoothColor() },
            };
            worksheetProductsListing.getColumn(4 + index).width = 45;
          });
        }
        //Add attributes based on catalog attributes
        else if(!!attributes && Array.isArray(attributes)) {
          attributes.forEach((attribute, index) => {
            worksheetProductsListing.getCell(1, 2 + index).value = attribute.name;
            worksheetProductsListing.getCell(1, 2 + index).fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: getRandomSmoothColor() },
            };
            worksheetProductsListing.getColumn(4 + index).width = 45;
          })

        }

        //VALUES - Add values to rows
        tmpMyCatalog.forEach((item, index) => {
          //Column 1# - Add Column Images
          //Add image to excel
          worksheetProductsListing.getCell(2 + index, 1).value = item.images_arr.reduce((a, b) => a + b.original_name + ",", '');
          //Remove last "," from name
          if (item.images_arr.length > 0) worksheetProductsListing.getCell(2 + index, 1).value = worksheetProductsListing.getCell(2 + index, 1).value.slice(0, -1);


          //Column X# - Add Column Values
          //Add values to Product List Sheet
          Object.keys(item).filter(key => !['images_arr', 'created_at', 'updated_at'].includes(key)).forEach((key, keyIndex) => {
            worksheetProductsListing.getCell(2 + index, 2 + keyIndex).value = item[key];
            const cell = worksheetProductsListing.getCell(2 + index, 2 + keyIndex);
            cell.alignment = { wrapText: true };
          });

        })
      }

      // Create a Blob and trigger download
      const blob = await workbook.xlsx.writeBuffer();
      const link = document.createElement('a');
      link.href = URL.createObjectURL(new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
      link.setAttribute('download', !!fileName ? fileName : 'data.xlsx');

      // Simulate click without creating a temporary element
      link.click();

      // Revoke the object URL (optional for cleanup)
      URL.revokeObjectURL(link.href);

      return false
    } catch (error) {
      console.log("Unexpected Error ", error);
      return 'Unexpected error downloading table';
    }
  }
}

export default utils;