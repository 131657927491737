
import { useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import ImageCatalogTable from '../imageList/ImagesCatalogTable';
import ImageListDrawer from '../imageList/ImageListDrawer';
import RelateToProductsDrawer from '../imageList/RelateToProductsDrawer';

export default function ImageCatalogContainer() {

  const setImages = useStoreActions(state => state.setImages);
  const showUploadImages = useStoreState(state => state.showUploadImages);
  const setImagesByProduct = useStoreActions(state => state.setImagesByProduct);
  const showRelateToProducts = useStoreState(state => state.showRelateToProducts);
  const setShowRelateToProducts = useStoreActions(state => state.setShowRelateToProducts);
  const fetchAllImagesFromProject = useStoreActions(state => state.fetchAllImagesFromProject);
  const fetchAllProductsFromProject = useStoreActions(state => state.fetchAllProductsFromProject);
  useEffect(() => {
    fetchAllImagesFromProject();
    fetchAllProductsFromProject();

    return () => {
      setImages([]);
      setImagesByProduct([]);
      setShowRelateToProducts(false)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div className='image-catalog-container-custom-styles'>
      <ImageCatalogTable />
      {showUploadImages && <ImageListDrawer />}
      {showRelateToProducts && <RelateToProductsDrawer />}
    </div>
  )
}
