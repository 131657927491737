import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useEffect } from 'react';
import { drawerFormWidth } from '../containers/TopDrawer';
import AttributeForm from './AttributeForm';

export default function AttributesListDrawer() {
	const loadingFormData = useStoreState(state => state.loadingFormData);
	const setLoadingFormData = useStoreActions(state => state.setLoadingFormData);
	const showFormAttribute = useStoreState(state => state.showFormAttribute);


	useEffect(() => {
		if (loadingFormData) {
			setLoadingFormData(false);
		}
		return () => {
			setLoadingFormData(false)
		};
	}, [loadingFormData, setLoadingFormData])

	return (
		<Box sx={{ display: 'flex' }}>
			<Drawer
				sx={{
					width: drawerFormWidth,
					flexShrink: 0,
					'& .MuiDrawer-paper': {
						width: drawerFormWidth,
						top: "64px", // Subtract width of header
					},
				}}
				variant="persistent"
				anchor="right"
				open={showFormAttribute}
			>
				{!loadingFormData && <AttributeForm />}
			</Drawer>
		</Box>
	);
}
