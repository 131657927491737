import axios from 'axios';

// Create an Axios instance
const axiosInstance = axios.create();

// Add a request interceptor
axiosInstance.interceptors.request.use((config) => {
    config.metadata = { startTime: new Date() };
    return config;
}, (error) => {
    return Promise.reject(error);
});

// Add a response interceptor
axiosInstance.interceptors.response.use((response) => {
    const endTime = new Date();
    response.config.metadata.endTime = endTime;
    response.duration = endTime - response.config.metadata.startTime;
    return response;
}, (error) => {
    const endTime = new Date();
    error.config.metadata.endTime = endTime;
    error.duration = endTime - error.config.metadata.startTime;
    return Promise.reject(error);
});

export default axiosInstance;
