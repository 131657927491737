import CloseIcon from '@mui/icons-material/Close';
import { Chip } from '@mui/material';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';


export default function PreviewTable({ catalogAttributes }) {

    const apiRef = useGridApiRef();
    let columns = [
        {
            field: 'name',
            headerName: 'Name',
        },
        {
            field: 'unique_code',
            type: 'boolean',
            headerName: 'Is Identifier',
        },
        {
            field: 'no_ia',
            type: 'boolean',
            headerName: 'Dont Generate',
        },
        {
            field: 'type',
            headerName: 'Type',
            valueFormatter: (value) => value,
            renderCell: (params) => <Chip label={params.row.type} variant="outlined" style={{padding:'1px', fontSize:'10px'}} />,
        },
        {
            field: 'prompt',
            headerName: 'Prompt',
            valueFormatter: (value) => value,
            renderCell: (params) => params.row.has_prompt && params.row.type === 'Text Field' ? params.row.prompt : <CloseIcon style={{ fontSize: '20px', color: 'rgba(0, 0, 0, 0.38)', position: 'absolute', marginTop: '8px' }} />,
        },
        {
            field: 'options',
            headerName: 'Options',
            renderCell: (params) => !!params.row.options.length ? params.row.options.length  : <CloseIcon style={{ fontSize: '20px', color: 'rgba(0, 0, 0, 0.38)', position: 'absolute', marginTop: '8px' }} />,
        }
    ]
    return (
        <div className='preview-data-grid' style={{ width: '100%', marginTop:'5%' }}>
            <DataGrid
                disableColumnSorting
                disableColumnFilter 
                apiRef={apiRef}
                getRowId={(row) => row.name}
                rows={catalogAttributes}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                    }
                }}
                density="compact" 
                pageSizeOptions={[5, 10, 15]}
                checkboxSelection={false}
                hideFooterSelectedRowCount={true}
                disableMultipleRowSelection={true}
            />
        </div>
    )
}
